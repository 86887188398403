import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const ButtonLoaderBase = styled.span<{ size?: number }>`
    position: relative;
    display: inline-block;
    width: ${({ size: themeSize = 24 }) => themeSize}px;
    height: ${({ size: themeSize = 24 }) => themeSize}px;
    
    border-radius: 50%;
    animation: ${rotate} 1s linear infinite;

    background: conic-gradient(from 90deg at 50% 50%, rgba(123, 83, 233, 0.0001) 0deg, #9E80EF 360deg);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #fff 0);
    mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #fff 0);
`;
