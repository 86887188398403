import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

import { SwitchWrapper } from 'components/switch-wrapper';

import { SnackSwitchWrapperBase } from './snack-switch-wrapper.styles';

export type SnackSwitchWrapperProps = {
  switchKey: string | number | boolean | null;
  transitionDuration?: number;
};

export const SnackSwitchWrapper: FC<SnackSwitchWrapperProps & ClassNameProps> = memo(({
  className, style, switchKey, children, transitionDuration = 600,
}) => (
  <SwitchWrapper switchKey={switchKey} transitionDuration={transitionDuration} classNames="snack">
    <SnackSwitchWrapperBase className={className} style={style} transitionDuration={transitionDuration}>
      {children}
    </SnackSwitchWrapperBase>
  </SwitchWrapper>
));
