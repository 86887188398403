import styled from 'styled-components';
import { ignoreClass } from '@spatium/wallet-kit';

export const FadeSwitchWrapperBase = styled.div<{ transitionDuration?: number }>`
  display: contents;

  &.fade-enter > :not(.${ignoreClass}),
  &.fade-enter > .${ignoreClass} > :not(.${ignoreClass}),
  &.fade-enter > .${ignoreClass} > .${ignoreClass} > :not(.${ignoreClass}) {
    opacity: 0;
  }

  &.fade-enter.fade-enter-active > :not(.${ignoreClass}),
  &.fade-enter.fade-enter-active > .${ignoreClass} > :not(.${ignoreClass}),
  &.fade-enter.fade-enter-active > .${ignoreClass} > .${ignoreClass} > :not(.${ignoreClass}) {
    opacity: 1;
    transition: ${({ transitionDuration = 300 }) => transitionDuration}ms;
  }

  &.fade-exit > :not(.${ignoreClass}),
  &.fade-exit > .${ignoreClass} > :not(.${ignoreClass}),
  &.fade-exit > .${ignoreClass} > .${ignoreClass} > :not(.${ignoreClass}) {
    opacity: 1;
  }

  &.fade-exit.fade-exit-active > :not(.${ignoreClass}),
  &.fade-exit.fade-exit-active > .${ignoreClass} > :not(.${ignoreClass}),
  &.fade-exit.fade-exit-active > .${ignoreClass} > .${ignoreClass} > :not(.${ignoreClass}) {
    opacity: 0;
    transition: ${({ transitionDuration = 300 }) => transitionDuration}ms;
  }

  &.cookie-enter > :not(.${ignoreClass}),
  &.cookie-enter > .${ignoreClass} > :not(.${ignoreClass}),
  &.cookie-enter > .${ignoreClass} > .${ignoreClass} > :not(.${ignoreClass}) {
    opacity: 0;
    transform: translateY(100%);
  }

  &.cookie-enter.cookie-enter-active > :not(.${ignoreClass}),
  &.cookie-enter.cookie-enter-active > .${ignoreClass} > :not(.${ignoreClass}),
  &.cookie-enter.cookie-enter-active > .${ignoreClass} > .${ignoreClass} > :not(.${ignoreClass}) {
    opacity: 1;
    transform: translateY(0);
    transition: ${({ transitionDuration = 300 }) => transitionDuration}ms;
  }

  &.cookie-exit > :not(.${ignoreClass}),
  &.cookie-exit > .${ignoreClass} > :not(.${ignoreClass}),
  &.cookie-exit > .${ignoreClass} > .${ignoreClass} > :not(.${ignoreClass}) {
    opacity: 1;
    transform: translateY(0);
  }

  &.cookie-exit.cookie-exit-active > :not(.${ignoreClass}),
  &.cookie-exit.cookie-exit-active > .${ignoreClass} > :not(.${ignoreClass}),
  &.cookie-exit.cookie-exit-active > .${ignoreClass} > .${ignoreClass} > :not(.${ignoreClass}) {
    opacity: 0;
    transform: translateY(100%);
    transition: ${({ transitionDuration = 300 }) => transitionDuration}ms;
  }
`;
