import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type AttentionProps = {
  size?: number;
};

export const Attention: FC<AttentionProps & ClassNameProps> = memo(({
  size = 64,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.6666 34.6654H29.3333V18.6654H34.6666M34.6666 45.332H29.3333V39.9987H34.6666M32 5.33203C28.4981 5.33203 25.0304 6.02179 21.7951 7.36191C18.5597 8.70204 15.62 10.6663 13.1438 13.1425C8.14283 18.1435 5.33331 24.9263 5.33331 31.9987C5.33331 39.0711 8.14283 45.8539 13.1438 50.8549C15.62 53.3311 18.5597 55.2954 21.7951 56.6355C25.0304 57.9756 28.4981 58.6654 32 58.6654C39.0724 58.6654 45.8552 55.8559 50.8562 50.8549C55.8571 45.8539 58.6666 39.0711 58.6666 31.9987C58.6666 28.4968 57.9769 25.0292 56.6368 21.7938C55.2966 18.5585 53.3324 15.6187 50.8562 13.1425C48.3799 10.6663 45.4402 8.70204 42.2049 7.36191C38.9695 6.02179 35.5019 5.33203 32 5.33203Z" fill="#D65151" />
  </svg>
));
