import { FC, Fragment, memo, Ref, RefObject, useCallback, useMemo, useState } from 'react';
import { layout, OptionalHandle, Portal, Pressable, Translated, useOptionalRef, useWindowDimensions } from '@spatium/wallet-kit';
import { Chain, Connector, useConnect, useAccount } from 'wagmi'
import ClickAwayListener from 'react-click-away-listener';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { Column, Row, Spacing, Text, theme } from 'theme';

import { sliceString } from 'lib/utils';
import { ChainObject, chains } from 'lib/chains';
import { HeadedView } from 'views/headed';
import { FadeSwitchWrapper } from 'components/fade-switch-wrapper';
import { PrimaryButton } from 'components/primary-button';
import { ButtonLoader as Loader } from 'components/button-loader';
import { ModalContainer } from 'components/modal';
import { Cookie } from 'components/cookie';

import { QuestionIcon } from 'assets/question-icon';
import { CheckMark } from 'assets/check-mark';
import { UnikaLogo } from 'assets/unika-logo';
import { Logout } from 'assets/logout';
import { ModalDottedLine } from 'assets/modal-dotted-line';
import { MetaLogo } from 'assets/meta-logo';
import { Logo } from 'assets/logo';
import { DottedLine } from 'assets/dotted-line';
import { Attention } from 'assets/attention';
import { WalletConnectLogo } from 'assets/wallet-connect-logo';
import { CoinbaseLogo } from 'assets/coinbase-logo';
import { MainUnikaLogo } from 'assets/main-unika-logo';
import { Support } from 'assets/support';
import { MobileLogo } from 'assets/mobile-logo';
import EmployeeAva from 'assets/employee.jpg';

import { Status, WhitelistEntry } from './main';
import {
  MobileModalWrapper,
  ModalButton,
  BaseModalWrapper,
  Time,
  TimeToolTip,
  WelcomeModalWrapper,
  StepWrapper,
  Step,
  TextWithBackground,
  MetamaskModalClose,
  ErrorModalWrapper,
  ModalHeader,
  Close,
  List,
  Warning,
  MainWrapper,
  SecondaryButton,
  Verified,
  VerticalLine,
  IconWrapper,
  ToolTip,
  StatusDrop,
  BottomModalWrapper,
  ChainsListModal,
  HeadChainsModal,
  ConnectWalletWrapper,
  ConnectWalletItem,
  ConnectWalletModalWrapper,
  BottomModalHeader,
  Arrow,
  ChainsWrapper,
  SelectedChain,
  ChainDropdown,
  ChainItem,
  Link,
  SupportLink,
  PossibilityWrapper,
  Possibility,
  PossibilityWindow,
  Info,
  Line,
  WindowCloseButtonWrapper,
  SupportWrapper,
  Employee,
  Notfication,
  EmployeeSupportWindow,
  Arm,
  StepList,
  ModalButtonWrapper,
  StatusDropWrapper,
  WhiteListModalWrapper,
  AccountId,
  WelcomeModalBase,
  Overlay,
  Scrollable,
  ConfirmModalWrapper,
  ConfirmModalButtonWrapper,
  WelcomeModalContentWrapper,
} from './main.styles';

type BaseModalProps = {
  modalRef: Ref<OptionalHandle>;
  onClose?: () => void;
};

type WalletsModalProps = BaseModalProps & {
  privacyLink: string;
  termsLink: string;
  connectors: Connector[];
  onConnect: (connector: Connector) => void;
};

const WalletsModalDesktop: FC<WalletsModalProps> = memo(({ privacyLink, termsLink, modalRef, connectors, onClose, onConnect }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.background} themePadding={theme.space(16)}>
      {() => (
        <ConnectWalletModalWrapper horizontalAlign="center">
          <Pressable onClick={onClose}>
            <Close />
          </Pressable>
          <Text themeFont={theme.fonts.i4.override({ size: theme.space(26), lineHeight: theme.space(31) })} themeTextColor={theme.colors.white} transform="uppercase" horizontalAlign='center'>
            <Translated id="app.main.connect-wallets-modal.title" />
          </Text>

          <Spacing themeSpace={theme.space(40)} />

          <ConnectWalletWrapper verticalAlign="center" horizontalAlign="space-between">
            {connectors.map((connector) => connector.ready && (
              <Pressable key={connector.id} onClick={() => onConnect(connector)}>
                <ConnectWalletItem verticalAlign="center" horizontalAlign="center">
                  {connector.id === 'metaMask' ? <>
                    <MetaLogo size={60} />
                    <Spacing themeSpace={theme.space(16)} />
                    <Text themeFont={theme.fonts.i1} themeTextColor={theme.colors.white} transform="uppercase" horizontalAlign='center'>
                      <Translated id="app.main.connect-wallets-modal.meta" />
                    </Text>
                  </> : connector.id === 'walletConnect' ? <>
                    <WalletConnectLogo size={60} />
                    <Spacing themeSpace={theme.space(16)} />
                    <Text themeFont={theme.fonts.i1} themeTextColor={theme.colors.white} transform="uppercase" horizontalAlign='center'>
                      <Translated id="app.main.connect-wallets-modal.wallet-connect" />
                    </Text>
                  </> : connector.id === 'coinbaseWallet' ? <>
                    <CoinbaseLogo size={60} />
                    <Spacing themeSpace={theme.space(16)} />
                    <Text themeFont={theme.fonts.i1} themeTextColor={theme.colors.white} transform="uppercase" horizontalAlign='center'>
                      <Translated id="app.main.connect-wallets-modal.coinbase" />
                    </Text>
                  </> : <>
                    <Text themeFont={theme.fonts.i1} themeTextColor={theme.colors.white} transform="uppercase" horizontalAlign='center'>
                      Unknown connector
                    </Text>
                  </>}
                </ConnectWalletItem>
              </Pressable>
            ))}
          </ConnectWalletWrapper>

          <Spacing themeSpace={theme.space(40)} />

          <Text themeFont={theme.fonts.i7} themeTextColor={theme.colors.darkWhite}>
            <Translated id="app.main.connect-wallets-modal.bottom-text" values={{
              privacy: <Link underlineColor={theme.colors.darkWhite} href={privacyLink} target="_blank" rel="noreferrer"><Translated id="app.main.privacy" /></Link>,
              terms: <Link underlineColor={theme.colors.darkWhite} href={termsLink} target="_blank" rel="noreferrer"><Translated id="app.main.terms" /></Link>,
            }} />
          </Text>
        </ConnectWalletModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

type WelcomeModalProps = BaseModalProps & {
  innerWidth: number;
};

const WelcomeModal: FC<WelcomeModalProps> = memo(({ innerWidth, modalRef, onClose }) => {
  return (
    <Portal tag="modal-root">
      <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.background} themePadding={theme.space(16)}>
        {() => (
          <WelcomeModalBase>
            <WelcomeModalWrapper horizontalAlign="center">
              <Warning horizontalAlign="center" verticalAlign="center">
                <Text themeFont={theme.fonts.i6} themeTextColor={theme.colors.black} horizontalAlign="center">
                  <Translated id="app.main.welcome-modal.warning" />
                </Text>
              </Warning>
              <Spacing themeSpace={theme.space(innerWidth > 768 ? 32 : 16)} />

              <WelcomeModalContentWrapper themePadding={[theme.space(0), theme.space(innerWidth > 768 ? 32 : 16)]} horizontalAlign="center">
                <MainUnikaLogo {...innerWidth <= 768 && { size: 128 }} />
                <Spacing themeSpace={theme.space(innerWidth > 768 ? 40 : 16)} />
                <Text themeFont={innerWidth > 768 ? theme.fonts.i4.override({ weight: 700 }) : theme.fonts.i4.override({ size: theme.space(26), lineHeight: theme.space(31) })} themeTextColor={theme.colors.white} horizontalAlign="center" transform="uppercase">
                  <Translated id="app.main.welcome-modal.title" />
                </Text>
                <Spacing themeSpace={theme.space(12)} />
                <Text themeFont={innerWidth > 768 ? theme.fonts.i7.override({ size: theme.space(16) }) : theme.fonts.i6} themeTextColor={theme.colors.darkWhite} horizontalAlign="center">
                  <Translated id="app.main.welcome-modal.possibility" />
                </Text>
                <Text themeFont={innerWidth > 768 ? theme.fonts.i7.override({ size: theme.space(16) }) : theme.fonts.i6} themeTextColor={theme.colors.darkWhite} horizontalAlign="center">
                  <Translated id="app.main.welcome-modal.claim" />
                </Text>

                {innerWidth > 768 && <>
                  <Spacing themeSpace={theme.space(16)} />
                  <Line />
                </>}
                <Spacing themeSpace={theme.space(innerWidth > 768 ? 16 : 40)} />

                <StepList horizontalAlign="space-between">
                  <StepWrapper>
                    <Row>
                      <Step horizontalAlign="center" verticalAlign="center">
                        <Text themeTextColor={theme.colors.grayDark} themeFont={theme.fonts.i2.override({ weight: 500 })}>1</Text>
                      </Step>
                      <Spacing themeSpace={theme.space(8)} />
                      <Text themeFont={theme.fonts.i7.override({ weight: 500 })} themeTextColor={theme.colors.darkWhite} transform="uppercase">
                        <Translated id="app.main.welcome-modal.first-step-title" />
                      </Text>
                    </Row>
                    <Spacing themeSpace={theme.space(8)} />
                    <Text themeFont={theme.fonts.i7.override({ size: theme.space(16) })} themeTextColor={theme.colors.white}>
                      <Translated id="app.main.welcome-modal.first-step-text" />
                    </Text>
                  </StepWrapper>
                  <StepWrapper>
                    <Row>
                      <Step horizontalAlign="center" verticalAlign="center">
                        <Text themeTextColor={theme.colors.grayDark} themeFont={theme.fonts.i2.override({ weight: 500 })}>2</Text>
                      </Step>
                      <Spacing themeSpace={theme.space(8)} />
                      <Text themeFont={theme.fonts.i7.override({ weight: 500 })} themeTextColor={theme.colors.darkWhite} transform="uppercase">
                        <Translated id="app.main.welcome-modal.second-step-title" />
                      </Text>
                    </Row>
                    <Spacing themeSpace={theme.space(8)} />
                    <Text themeFont={theme.fonts.i7.override({ size: theme.space(16) })} themeTextColor={theme.colors.white}>
                      <Translated id="app.main.welcome-modal.second-step-text" />
                    </Text>
                  </StepWrapper>
                  <StepWrapper>
                    <Row>
                      <Step horizontalAlign="center" verticalAlign="center">
                        <Text themeTextColor={theme.colors.grayDark} themeFont={theme.fonts.i2.override({ weight: 500 })}>3</Text>
                      </Step>
                      <Spacing themeSpace={theme.space(8)} />
                      <Text themeFont={theme.fonts.i7.override({ weight: 500 })} themeTextColor={theme.colors.darkWhite} transform="uppercase">
                        <Translated id="app.main.welcome-modal.third-step-title" />
                      </Text>
                    </Row>
                    <Spacing themeSpace={theme.space(8)} />
                    <Text themeFont={theme.fonts.i7.override({ size: theme.space(16) })} themeTextColor={theme.colors.white}>
                      <Translated id="app.main.welcome-modal.third-step-text" values={{
                        time: <>
                          <Time data-tooltip-id="modal-time">
                            <Translated id="app.main.welcome-modal.time" />
                          </Time>
                          <ReactTooltip id="modal-time" className="time-tooltip">
                            <TimeToolTip themeFont={theme.fonts.i7.override({ size: theme.space(16) })} themeTextColor={theme.colors.white} horizontalAlign="center">
                              <Translated id="app.main.welcome-modal.tooltip" />
                            </TimeToolTip>
                          </ReactTooltip>
                        </>,
                        unika: <Link href={process.env.REACT_APP_UNIKA_DAPP} target="_blank" rel="noreferrer">
                          <Translated id="app.main.welcome-modal.unika" />
                        </Link>
                      }} />
                    </Text>
                  </StepWrapper>
                </StepList>

                <Spacing themeSpace={theme.space(innerWidth > 768 ? 40 : 80)} />
                {innerWidth > 768 &&
                  <Pressable onClick={onClose}>
                    <ModalButton themeWidth={theme.space(174)}>
                      <Text
                        horizontalAlign='center'
                        transform='uppercase'
                        themeFont={theme.fonts.i1}
                        themeTextColor={theme.colors.white}
                      >
                        <Translated id="app.main.welcome-modal.button" />
                      </Text>
                    </ModalButton>
                  </Pressable>}
              </WelcomeModalContentWrapper>
            </WelcomeModalWrapper>
            {innerWidth <= 768 && <ModalButtonWrapper>
              <Pressable onClick={onClose}>
                <ModalButton>
                  <Text
                    horizontalAlign='center'
                    transform='uppercase'
                    themeFont={theme.fonts.i1}
                    themeTextColor={theme.colors.white}
                  >
                    <Translated id="app.main.welcome-modal.button" />
                  </Text>
                </ModalButton>
              </Pressable>
            </ModalButtonWrapper>}
          </WelcomeModalBase>
        )}
      </ModalContainer>
    </Portal>
  )
});

const WalletsMobileModal: FC<WalletsModalProps> = memo(({ privacyLink, termsLink, modalRef, connectors, onClose, onConnect }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.background} themePadding={theme.space(16)}>
      {() => (
        <BottomModalWrapper>
          <BottomModalHeader horizontalAlign="space-between">
            <Text themeFont={theme.fonts.i4.override({ size: theme.space(26), lineHeight: theme.space(32) })} themeTextColor={theme.colors.white} transform="uppercase">
              <Translated id="app.main.connect-wallets-modal.title" />
            </Text>
            <Spacing themeSpace={theme.space(16)} />
            <Pressable onClick={onClose}>
              <Close />
            </Pressable>
          </BottomModalHeader>

          <Spacing themeSpace={theme.space(32)} />

          <ConnectWalletWrapper>
            {connectors.map((connector) => connector.ready && (
              <Pressable key={connector.id} onClick={() => onConnect(connector)}>
                <ConnectWalletItem>
                  {connector.id === 'metaMask' ? <>
                    <Spacing themeSpace={theme.space(16)} />
                    <MetaLogo size={40} />
                    <Spacing themeSpace={theme.space(16)} />
                    <Text themeFont={theme.fonts.i1} themeTextColor={theme.colors.white} transform="uppercase">
                      <Translated id="app.main.connect-wallets-modal.meta" />
                    </Text>
                  </> : connector.id === 'walletConnect' ? <>
                    <Spacing themeSpace={theme.space(16)} />
                    <WalletConnectLogo size={40} />
                    <Spacing themeSpace={theme.space(16)} />
                    <Text themeFont={theme.fonts.i1} themeTextColor={theme.colors.white} transform="uppercase">
                      <Translated id="app.main.connect-wallets-modal.wallet-connect" />
                    </Text>
                  </> : connector.id === 'coinbaseWallet' ? <>
                    <Spacing themeSpace={theme.space(16)} />
                    <CoinbaseLogo size={40} />
                    <Spacing themeSpace={theme.space(16)} />
                    <Text themeFont={theme.fonts.i1} themeTextColor={theme.colors.white} transform="uppercase">
                      <Translated id="app.main.connect-wallets-modal.coinbase" />
                    </Text>
                  </> : <>
                    <Text themeFont={theme.fonts.i1} themeTextColor={theme.colors.white} transform="uppercase" horizontalAlign='center'>
                      Unknown connector
                    </Text>
                  </>}
                </ConnectWalletItem>
                <Spacing themeSpace={theme.space(16)} />
              </Pressable>
            ))}
          </ConnectWalletWrapper>

          <Spacing themeSpace={theme.space(32)} />

          <Text themeFont={theme.fonts.i5.override({ size: theme.space(14), lineHeight: theme.space(18) })} themeTextColor={theme.colors.darkWhite}>
            <Translated id="app.main.connect-wallets-modal.bottom-text" values={{
              privacy: <Link underlineColor={theme.colors.darkWhite} href={privacyLink} target="_blank" rel="noreferrer"><Translated id="app.main.privacy" /></Link>,
              terms: <Link underlineColor={theme.colors.darkWhite} href={termsLink} target="_blank" rel="noreferrer"><Translated id="app.main.terms" /></Link>,
            }} />
          </Text>
        </BottomModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

type ChainsModalProps = BaseModalProps & {
  chains: Array<ChainObject>;
  selected: ChainObject;
  onSelect: (chain: ChainObject) => void;
};

const ChainsModal: FC<ChainsModalProps> = memo(({ chains, selected, modalRef, onSelect, onClose }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.background} themePadding={theme.space(16)}>
      {() => (
        <BottomModalWrapper>
          <HeadChainsModal verticalAlign="center" horizontalAlign="space-between">
            <Text themeFont={theme.fonts.i4.override({ size: theme.space(26), lineHeight: theme.space(31) })} themeTextColor={theme.colors.white} transform="uppercase">
              <Translated id="app.main.chains-modal.title" />
            </Text>
            <Pressable onClick={onClose}>
              <Close />
            </Pressable>
          </HeadChainsModal>
          <Spacing themeSpace={theme.space(32)} />
          <ChainsListModal>
            {chains.map((x) => (
              <ChainItem key={x.name} onClick={() => onSelect(x)} active={selected.name === x.name}>
                {x.icon}
                <Spacing themeSpace={theme.space(8)} />
                <Text themeFont={theme.fonts.i1.override({ weight: 700 })} themeTextColor={theme.colors.darkWhite}>
                  {x.name}
                </Text>
              </ChainItem>
            ))}
          </ChainsListModal>
        </BottomModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

const InfoModal: FC<BaseModalProps> = memo(({ modalRef, onClose }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} themeColor={theme.colors.foreground} themePadding={[theme.space(16)]}>
      {() => (
        <MobileModalWrapper themePadding={[theme.space(32), theme.space(16)]} horizontalAlign="center">
          <Info themeFont={theme.fonts.i7.override({ lineHeight: theme.space(17) })} themeTextColor={theme.colors.darkWhite} horizontalAlign="center">
            <Translated id={'app.main.info-modal.text'} />
          </Info>

          <Spacing themeSpace={theme.space(16)} />

          <Pressable onClick={onClose}>
            <ModalButton>
              <Info themeFont={theme.fonts.i1} themeTextColor={theme.colors.white} transform="uppercase" horizontalAlign="center">
                <Translated id="app.main.info-modal.got" />
              </Info>
            </ModalButton>
          </Pressable>
        </MobileModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

type ConfirmModalProps = {
  innerWidth: number;
  modalRef: Ref<OptionalHandle>;
  onConfirm: () => void;
  onReject: () => void;
};

const ConfirmModal: FC<ConfirmModalProps> = memo(({ innerWidth, modalRef, onConfirm, onReject }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.background} themePadding={theme.space(16)}>
      {() => (
        <ConfirmModalWrapper horizontalAlign="center">
          <Column horizontalAlign={innerWidth > 768 ? "center" : "left"}>
            <Text themeFont={innerWidth > 768 ? theme.fonts.i4 : theme.fonts.i4.override({ size: theme.space(26), lineHeight: theme.space(31) })} themeTextColor={theme.colors.white} transform="uppercase" horizontalAlign={innerWidth > 768 ? 'center' : 'left'}>
              <Translated id="app.main.confirm-modal.title" />
            </Text>
            <Spacing themeSpace={theme.space(12)} />
            <Text themeFont={innerWidth > 768 ? theme.fonts.i5 : theme.fonts.i7.override({ lineHeight: theme.space(17) })} themeTextColor={theme.colors.darkWhite} horizontalAlign={innerWidth > 768 ? 'center' : 'left'}>
              {innerWidth > 768
                ? <Translated id="app.main.confirm-modal-desktop.info" />
                : <Translated id="app.main.confirm-modal-mobile.info" />}

              <TextWithBackground themeFont={theme.fonts.m1} themeTextColor={theme.colors.white}>
                <Translated id="app.main.confirm-modal.airdrop" />
              </TextWithBackground>:
            </Text>
            <Spacing themeSpace={theme.space(16)} />
            <TextWithBackground themeFont={theme.fonts.m1} themeTextColor={theme.colors.white} horizontalAlign={innerWidth > 768 ? 'center' : 'left'}>
              <Translated id="app.main.confirm-modal.question" />
            </TextWithBackground>
          </Column>

          <Spacing themeSpace={theme.space(40)} />

          <ConfirmModalButtonWrapper>
            <Pressable onClick={onReject}>
              <ModalButton themeWidth={theme.space(206)} noBackground>
                <Text
                  horizontalAlign='center'
                  transform='uppercase'
                  themeFont={theme.fonts.i1}
                  themeTextColor={theme.colors.white}
                >
                  <Translated id="app.main.confirm-modal.reject" />
                </Text>
              </ModalButton>
            </Pressable>
            <Spacing themeSpace={theme.space(20)} />
            <Pressable onClick={onConfirm}>
              <ModalButton themeWidth={theme.space(206)}>
                <Text
                  horizontalAlign='center'
                  transform='uppercase'
                  themeFont={theme.fonts.i1}
                  themeTextColor={theme.colors.white}
                >
                  <Translated id="app.main.confirm-modal.confirm" />
                </Text>
              </ModalButton>
            </Pressable>
          </ConfirmModalButtonWrapper>
        </ConfirmModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

type MetamaskModalProps = {
  modalRef: Ref<OptionalHandle>;
  onInstall: () => void;
  onTryAgain: () => void;
  onClose: () => void;
};

const MetamaskModal: FC<MetamaskModalProps> = memo(({ modalRef, onInstall, onTryAgain, onClose }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.background} themePadding={theme.space(16)}>
      {() => (
        <BaseModalWrapper horizontalAlign="center">
          <Pressable onClick={onClose}>
            <MetamaskModalClose />
          </Pressable>

          <Column horizontalAlign="center">
            <Text themeFont={theme.fonts.i4} themeTextColor={theme.colors.white} transform="uppercase" horizontalAlign='center'>
              <Translated id="app.main.metamask-modal.title" />
            </Text>
            <Spacing themeSpace={theme.space(12)} />
            <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.darkWhite} horizontalAlign='center'>
              <Translated id="app.main.metamask-modal.info" />
            </Text>
          </Column>

          <Spacing themeSpace={theme.space(40)} />

          <Row>
            <Pressable onClick={onInstall}>
              <PrimaryButton themeWidth={theme.space(285)}>
                <MetaLogo />
                <Spacing themeSpace={theme.space(16)} />
                <Text
                  horizontalAlign='center'
                  transform='uppercase'
                  themeFont={theme.fonts.i1}
                  themeTextColor={theme.colors.white}
                >
                  <Translated id="app.main.metamask-modal.extension" />
                </Text>
              </PrimaryButton>
            </Pressable>
            <Spacing themeSpace={theme.space(20)} />
            <Pressable onClick={onTryAgain}>
              <PrimaryButton themeWidth={theme.space(285)}>
                <Text
                  horizontalAlign='center'
                  transform='uppercase'
                  themeFont={theme.fonts.i1}
                  themeTextColor={theme.colors.white}
                >
                  <Translated id="app.main.metamask-modal.try-again" />
                </Text>
              </PrimaryButton>
            </Pressable>
          </Row>
        </BaseModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

type ErrorModalProps = {
  modalRef: Ref<OptionalHandle>;
  onTryAgain: () => void;
};

const ErrorModal: FC<ErrorModalProps> = memo(({ modalRef, onTryAgain }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.background} themePadding={theme.space(16)}>
      {() => (
        <ErrorModalWrapper horizontalAlign="center" themePadding={[theme.space(32), theme.space(80)]}>
          <Attention />
          <Spacing themeSpace={theme.space(40)} />

          <Text themeFont={theme.fonts.i4} themeTextColor={theme.colors.white} transform="uppercase">
            <Translated id="app.main.error-modal.wrong" />
          </Text>
          <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.darkWhite} horizontalAlign="center">
            <Translated id="app.main.error-modal.try" />
          </Text>

          <Spacing themeSpace={theme.space(40)} />

          <Pressable onClick={onTryAgain}>
            <PrimaryButton themeWidth={theme.space(208)}>
              <Text
                horizontalAlign='center'
                transform='uppercase'
                themeFont={theme.fonts.i1}
                themeTextColor={theme.colors.white}
              >
                <Translated id="app.main.error-modal.try-again" />
              </Text>
            </PrimaryButton>
          </Pressable>
        </ErrorModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

type WhiteListModalProps = {
  innerWidth: number;
  whitelist: WhitelistEntry[];
  modalRef: Ref<OptionalHandle>;
  onWhitelistClose: () => void;
};

const WhiteListModal: FC<WhiteListModalProps> = memo(({ innerWidth, whitelist, modalRef, onWhitelistClose }) => (
  <Portal tag="modal-root">
    <ModalContainer modalRef={modalRef} stretch themeColor={theme.colors.foreground}>
      {() => (
        <WhiteListModalWrapper>
          <ModalHeader>
            <Text themeFont={innerWidth > 768 ? theme.fonts.i4 : theme.fonts.i4.override({ size: theme.space(26), lineHeight: theme.space(31) })} themeTextColor={theme.colors.white}>
              <Translated id="app.main.modal.title" />
            </Text>
            <Pressable onClick={onWhitelistClose}>
              <Close />
            </Pressable>
          </ModalHeader>
          <Spacing themeSpace={theme.space(32)} />
          <List>
            {whitelist?.map((x, index, arr) => (
              <Fragment key={index}>
                <Row horizontalAlign="space-between" themePadding={[theme.space(index === 0 ? 0 : 16), theme.space(0), theme.space(8), theme.space(0)]}>
                  <AccountId themeFont={theme.fonts.i5} themeTextColor={theme.colors.white}>{x.address?.toUpperCase()}</AccountId>
                  <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.white}>{moment(x.date).format('DD.MM.yyyy')}</Text>
                </Row>
                {index + 1 !== arr.length && <ModalDottedLine />}
              </Fragment>
            ))}
          </List>
        </WhiteListModalWrapper>
      )}
    </ModalContainer>
  </Portal>
));

export type MainViewProps = {
  status: Status;
  address?: string;
  selectedChain: ChainObject;
  connectedChain?: Chain;
  whitelist: WhitelistEntry[];
  infoModalRef: RefObject<OptionalHandle<{}>>;
  errorModalRef: RefObject<OptionalHandle<{}>>;
  welcomeModalRef: RefObject<OptionalHandle<{}>>;
  confirmSharingModalRef: RefObject<OptionalHandle<{}>>;
  metamaskModalRef: RefObject<OptionalHandle<{}>>;
  whitelistModalRef: RefObject<OptionalHandle<{}>>;
  onSelectChain: (selected: ChainObject) => void;
  onConnectWallet: (connector: Connector, chaindId: number) => void;
  onDisconnectWallet: () => void;
  initializeClaimRequest: () => void;
  onUnika: () => void;
  onCheckWhitelist: () => void;
  onInstallMetamask: () => void;
  onCloseMetamaskModal: () => void;
  onCloseWhiteListModal: () => void;
  onErrorTryAgain: () => void;
  onMetamaskTryAgain: () => void;
  onConfirmSharing: () => void;
  onCloseWelcome: () => void;
  onRejectSharing: () => void;
  onCloseInfoModal: () => void;
  onOpenInfoModal: () => void;
};

export const MainView: FC<MainViewProps> = memo(({
  status,
  address,
  selectedChain,
  connectedChain,
  metamaskModalRef,
  whitelist,
  infoModalRef,
  whitelistModalRef,
  confirmSharingModalRef,
  welcomeModalRef,
  errorModalRef,
  onSelectChain,
  onConnectWallet,
  onDisconnectWallet,
  initializeClaimRequest,
  onCheckWhitelist,
  onCloseWhiteListModal,
  onUnika,
  onInstallMetamask,
  onErrorTryAgain,
  onMetamaskTryAgain,
  onConfirmSharing,
  onCloseWelcome,
  onRejectSharing,
  onCloseInfoModal,
  onOpenInfoModal,
  onCloseMetamaskModal,
}) => {
  const { innerWidth } = useWindowDimensions();

  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showPossibility, setShowPossibility] = useState(false);

  const currentChain = useMemo(() => {
    return chains.find((x) => x.id === connectedChain?.id) ?? selectedChain;
  }, [connectedChain, selectedChain]);

  const [openChainDropdown, setOpenChainDropdown] = useState(false);
  const [chainsModalRef, openChainsModal, closeChainsModal] = useOptionalRef();
  const [connectWalletModalRef, openConnectWalletModal, closeConnectWalletModal] = useOptionalRef();

  const _onSelectChain = useCallback((selected: ChainObject) => {
    setOpenChainDropdown(false);
    closeChainsModal();

    onSelectChain(selected);
  }, [closeChainsModal, onSelectChain]);

  const onConnect = useCallback((connector: Connector) => {
    closeConnectWalletModal();
    onConnectWallet(connector, selectedChain.id);
  }, [onConnectWallet, closeConnectWalletModal, selectedChain.id]);

  const { connector } = useAccount();
  const { connectors } = useConnect();

  const loading = useMemo(() =>
    status === 'connecting' ||
    status === 'signing' ||
    status === 'sending-service' ||
    status === 'approving' ||
    status === 'sending-unika',
    [status]);

  const privacyLink = useMemo(() => `${process.env.REACT_APP_UNIKA_WEBSITE}/privacy-policy.pdf`, []);
  const termsLink = useMemo(() => `${process.env.REACT_APP_UNIKA_WEBSITE}/terms-of-use.pdf`, []);

  return (
    <HeadedView
      left={
        innerWidth > 768 ? <Logo /> : <MobileLogo />
      }
      right={
        <Row verticalAlign="center">
          <ClickAwayListener onClickAway={() => setOpenChainDropdown(false)}>
            <ChainsWrapper>
              <SelectedChain
                verticalAlign="center"
                onClick={innerWidth > 768
                  ? () => setOpenChainDropdown(prev => !prev)
                  : openChainsModal}
              >
                {currentChain.icon}
                <Spacing themeSpace={theme.space(innerWidth > 768 ? 8 : 4)} />
                {innerWidth > 768 && <>
                  <Text themeFont={theme.fonts.i5.override({ weight: 500 })} themeTextColor={theme.colors.darkWhite}>
                    {currentChain?.name}
                  </Text>
                  <Spacing themeSpace={theme.space(8)} />
                </>}
                <Arrow down={openChainDropdown} />
              </SelectedChain>
              <FadeSwitchWrapper switchKey={openChainDropdown} transitionDuration={200}>
                {openChainDropdown &&
                  <ChainDropdown>
                    {chains.map(x => (
                      <ChainItem key={x.name} onClick={() => _onSelectChain(x)} active={x.name === currentChain.name}>
                        {x.icon}
                        <Spacing themeSpace={theme.space(8)} />
                        <Text themeFont={theme.fonts.i5.override({ weight: 500 })} themeTextColor={theme.colors.darkWhite}>
                          {x.name}
                        </Text>
                      </ChainItem>
                    ))}
                  </ChainDropdown>}
              </FadeSwitchWrapper>
            </ChainsWrapper>
          </ClickAwayListener>
          <Spacing themeSpace={theme.space(16)} />
          <FadeSwitchWrapper switchKey={address ?? ''}>
            {address ?
              <Verified verticalAlign="center" horizontalAlign="center" themeSpacing={theme.space(8)}>
                {connector?.id === 'metaMask' && <MetaLogo />}
                {connector?.id === 'walletConnect' && <WalletConnectLogo />}
                {connector?.id === 'coinbaseWallet' && <CoinbaseLogo />}

                <Text themeFont={innerWidth > 768 ? theme.fonts.i2 : theme.fonts.i2.override({ weight: 600 })} themeTextColor={theme.colors.white} transform="uppercase">
                  {sliceString(address)}
                </Text>

                <VerticalLine />

                <Pressable onClick={onDisconnectWallet}>
                  <Logout {...innerWidth <= 768 && { size: 16 }} />
                </Pressable>
              </Verified>
              : <Pressable disabled={status === 'connecting'} onClick={openConnectWalletModal}>
                <SecondaryButton disabled={status === 'connecting'} {...innerWidth <= 768 && { small: true }}>
                  <Text themeFont={theme.fonts.i2} themeTextColor={theme.colors.black} transform="uppercase">
                    <Translated id="app.main.connect-wallet" />
                  </Text>
                </SecondaryButton>
              </Pressable>}
          </FadeSwitchWrapper>
        </Row>
      }>
      <Scrollable horizontalAlign="stretch" {...layout.expand}>
        <MainWrapper horizontalAlign="center" verticalAlign="top" themePadding={[theme.space(0), theme.space(16)]}>
          <FadeSwitchWrapper switchKey={status === 'initial' || status === 'connecting' || status === 'verified' || status === 'not-verified' || status === 'outdated' || status === 'signing' || status === 'sending-service' || status === 'approving' || status === 'sending-unika' ? '2' : status === 'checking' ? '1' : '0'}>
            {(status === 'initial' || status === 'connecting' || status === 'verified' || status === 'not-verified' || status === 'outdated' || status === 'signing' || status === 'sending-service' || status === 'approving' || status === 'sending-unika') &&
              <Column horizontalAlign='center'>
                <Text themeFont={innerWidth > 768 ? theme.fonts.i3 : theme.fonts.i3.override({ size: theme.space(26), lineHeight: theme.space(31) })} themeTextColor={theme.colors.white} transform="uppercase" horizontalAlign='center'>
                  <h1><Translated id="app.main.title" /></h1>
                </Text>

                <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.darkWhite} horizontalAlign='center'>
                  <Translated id="app.main.info" />
                  <IconWrapper {...innerWidth > 768 ? { onMouseEnter: () => setShowInfoTooltip(true), onMouseLeave: () => setShowInfoTooltip(false) } : { onClick: () => onOpenInfoModal() }}>
                    <QuestionIcon themeColor={theme.colors.white} {...innerWidth <= 768 && { size: 15 }} />
                    <FadeSwitchWrapper switchKey={showInfoTooltip}>
                      {showInfoTooltip &&
                        <ToolTip themeFont={theme.fonts.i7} themeTextColor={theme.colors.darkWhite} horizontalAlign="center">
                          <Translated id="app.main.tooltip"
                            values={{
                              privacy: <a href={privacyLink} target="_blank" rel="noreferrer"><Translated id="app.main.privacy" /></a>,
                              terms: <a href={termsLink} target="_blank" rel="noreferrer"><Translated id="app.main.terms" /></a>,
                            }} />
                        </ToolTip>}
                    </FadeSwitchWrapper>
                  </IconWrapper>
                </Text>

                <Spacing themeSpace={theme.space(40)} />

                <FadeSwitchWrapper switchKey={status === 'initial' || status === 'connecting' || status === 'verified' || status === 'signing' || status === 'sending-service' || status === 'approving' || status === 'sending-unika'}>
                  {(status === 'initial' || status === 'connecting' || status === 'verified' || status === 'signing' || status === 'sending-service' || status === 'approving' || status === 'sending-unika') &&
                    <Column horizontalAlign='center'>
                      <Pressable disabled={loading} onClick={status === 'verified' ? initializeClaimRequest : openConnectWalletModal}>
                        <PrimaryButton loading={loading}>
                          <FadeSwitchWrapper switchKey={status === 'connecting' ? '2' : status === 'signing' || status === 'approving' ? '1' : '0'}>
                            <Text themeFont={theme.fonts.i1} themeTextColor={theme.colors.white} transform="uppercase">
                              {status === 'connecting'
                                ? <Translated id="app.main.button.connecting" />
                                : status === 'signing' || status === 'approving'
                                  ? <Translated id="app.main.button.signing" />
                                  : status === 'sending-service' || status === 'sending-unika'
                                    ? <Translated id="app.main.button.waiting" />
                                    : <Translated id="app.main.button.claim" />}
                            </Text>
                          </FadeSwitchWrapper>
                        </PrimaryButton>
                      </Pressable>

                      <Spacing themeSpace={theme.space(12)} />

                      <FadeSwitchWrapper switchKey={status}>
                        <Text themeFont={theme.fonts.i6} themeTextColor={theme.colors.white} horizontalAlign="center">
                          {status === 'connecting'
                            ? <Translated id="app.main.connecting-info" />
                            : status === 'signing'
                              ? <Translated id="app.main.signing-tx" />
                              : status === 'approving'
                                ? <Translated id="app.main.approve-sharing" />
                                : status === 'sending-service'
                                  ? <Translated id="app.main.sending-service" />
                                  : status === 'sending-unika'
                                    ? <Translated id="app.main.sending-unika" />
                                    : status === 'verified'
                                      ? <Translated id="app.main.connected-info" />
                                      : <Translated id="app.main.claim-info" />}
                        </Text>
                      </FadeSwitchWrapper>
                    </Column>}
                </FadeSwitchWrapper>

                <FadeSwitchWrapper switchKey={status === 'verified' ? '2' : status === 'signing' || status === 'approving' ? '1' : '0'}>
                  {(status === 'verified' || status === 'signing' || status === 'sending-service' || status === 'approving' || status === 'sending-unika') &&
                    <Column horizontalAlign="center" themePadding={[theme.space(40), theme.space(0)]}>
                      <DottedLine />
                      <Spacing themeSpace={theme.space(40)} />

                      <CheckMark />
                      <Spacing themeSpace={theme.space(8)} />
                      <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.white} horizontalAlign="center">
                        <Translated id="app.main.verified-unika" />
                      </Text>
                    </Column>}
                </FadeSwitchWrapper>

                <FadeSwitchWrapper switchKey={status === 'not-verified' || status === 'outdated'}>
                  {(status === 'not-verified' || status === 'outdated') &&
                    <Column horizontalAlign="center">
                      <DottedLine />
                      <Spacing themeSpace={theme.space(40)} />

                      <Attention size={32} />
                      <Spacing themeSpace={theme.space(8)} />
                      <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.white} horizontalAlign="center">
                        {status === 'outdated'
                          ? <Translated id="app.main.outdated-unika" />
                          : <Translated id="app.main.not-verified-unika" />}
                      </Text>

                      <Spacing themeSpace={theme.space(40)} />

                      <Pressable onClick={onUnika}>
                        <PrimaryButton themeWidth={theme.space(383)}>
                          <UnikaLogo />
                          <Spacing themeSpace={theme.space(16)} />
                          <Text themeFont={theme.fonts.i1} themeTextColor={theme.colors.white} transform="uppercase">Get verified with UNIKA</Text>
                        </PrimaryButton>
                      </Pressable>

                      <Spacing themeSpace={theme.space(12)} />

                      <Text themeFont={theme.fonts.i6} themeTextColor={theme.colors.white}>
                        <Translated id="app.main.redirected" />
                      </Text>
                    </Column>}
                </FadeSwitchWrapper>
              </Column>}

            {status === 'checking' &&
              <Column horizontalAlign='center'>
                <Loader />
                <Text themeFont={theme.fonts.i4} themeTextColor={theme.colors.white} horizontalAlign="center">
                  <Translated id="app.main.wait" />
                </Text>
                <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.darkWhite} horizontalAlign="center">
                  <Translated id="app.main.received-request" />
                </Text>

                <Spacing themeSpace={theme.space(40)} />
                <DottedLine />
                <Spacing themeSpace={theme.space(40)} />

                <StatusDropWrapper horizontalAlign="space-around" verticalAlign="center">
                  <StatusDrop horizontalAlign="center">
                    <CheckMark />
                    <Spacing themeSpace={theme.space(8)} />
                    <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.white} horizontalAlign="center">
                      <Translated id="app.main.verified-unika" />
                    </Text>
                  </StatusDrop>

                  <Spacing themeSpace={theme.space(innerWidth > 768 ? 75 : 16)} />

                  <StatusDrop horizontalAlign="center">
                    <Loader size={30} />
                    <Spacing themeSpace={theme.space(8)} />
                    <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.white} horizontalAlign="center">
                      <Translated id="app.main.eligibility" />
                    </Text>
                  </StatusDrop>
                </StatusDropWrapper>
              </Column>}

            {(status === 'denied' || status === 'participate' || status === 'success') &&
              <Column horizontalAlign='center'>
                {status === 'success' &&
                  <CheckMark size={innerWidth > 768 ? 64 : 40} />}
                {(status === 'denied' || status === 'participate') &&
                  <Attention {...innerWidth <= 768 && { size: 40 }} />}

                <Text themeFont={theme.fonts.i4} themeTextColor={theme.colors.white} horizontalAlign="center" transform="uppercase">
                  {status === 'success' &&
                    <Translated id="app.main.success" />}
                  {(status === 'denied' || status === 'participate') &&
                    <Translated id="app.main.denied" />}
                </Text>
                <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.darkWhite} horizontalAlign="center">
                  {status === 'success' && <Translated id="app.main.whitelist" values={{ account: address?.toUpperCase() }} />}
                  {status === 'denied' && <Translated id="app.main.participate" />}
                  {status === 'participate' && <Translated id="app.main.denied-reason" values={{ account: address?.toUpperCase() }} />}
                </Text>

                <Spacing themeSpace={theme.space(40)} />

                <SecondaryButton onClick={onCheckWhitelist}>
                  <Text themeFont={theme.fonts.i2} themeTextColor={theme.colors.black} transform="uppercase">
                    <Translated id="app.main.check-whitelist" />
                  </Text>
                </SecondaryButton>

                <Spacing themeSpace={theme.space(40)} />
                <DottedLine />
                <Spacing themeSpace={theme.space(40)} />

                <StatusDropWrapper horizontalAlign="space-around" verticalAlign="top">
                  <StatusDrop horizontalAlign="center">
                    <CheckMark />
                    <Spacing themeSpace={theme.space(8)} />
                    <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.white} horizontalAlign="center">
                      <Translated id="app.main.verified-unika" />
                    </Text>
                  </StatusDrop>

                  <Spacing themeSpace={theme.space(16)} />

                  <StatusDrop horizontalAlign="center">
                    {(status === 'denied' || status === 'participate') &&
                      <Attention size={32} />}
                    {status === 'success' &&
                      <CheckMark size={32} />}

                    <Spacing themeSpace={theme.space(8)} />

                    <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.white} horizontalAlign="center">
                      <Translated id="app.main.not-confirmed" />
                    </Text>
                  </StatusDrop>
                </StatusDropWrapper>
              </Column>}
          </FadeSwitchWrapper>
        </MainWrapper>
      </Scrollable>

      {innerWidth > 768
        ? <WalletsModalDesktop
          modalRef={connectWalletModalRef}
          connectors={connectors}
          onClose={closeConnectWalletModal}
          onConnect={onConnect}
          privacyLink={privacyLink}
          termsLink={termsLink}
        />
        : <WalletsMobileModal
          modalRef={connectWalletModalRef}
          connectors={connectors}
          onClose={closeConnectWalletModal}
          onConnect={onConnect}
          privacyLink={privacyLink}
          termsLink={termsLink}
        />}

      {innerWidth <= 768 &&
        <ChainsModal
          modalRef={chainsModalRef}
          chains={chains}
          selected={currentChain}
          onClose={closeChainsModal}
          onSelect={_onSelectChain}
        />}

      <MetamaskModal
        modalRef={metamaskModalRef}
        onTryAgain={onMetamaskTryAgain}
        onClose={onCloseMetamaskModal}
        onInstall={onInstallMetamask}
      />

      <WhiteListModal
        innerWidth={innerWidth}
        whitelist={whitelist}
        modalRef={whitelistModalRef}
        onWhitelistClose={onCloseWhiteListModal}
      />

      <InfoModal
        modalRef={infoModalRef}
        onClose={onCloseInfoModal}
      />

      <WelcomeModal
        modalRef={welcomeModalRef}
        onClose={onCloseWelcome}
        innerWidth={innerWidth}
      />

      <ConfirmModal
        modalRef={confirmSharingModalRef}
        onConfirm={onConfirmSharing}
        onReject={onRejectSharing}
        innerWidth={innerWidth}
      />

      <ErrorModal
        modalRef={errorModalRef}
        onTryAgain={onErrorTryAgain}
      />

      <PossibilityWrapper window={showPossibility}>
        {showPossibility && <Overlay />}
        <FadeSwitchWrapper switchKey={status === 'denied' || status === 'participate' || status === 'success'}>
          {(status === 'denied' || status === 'participate' || status === 'success') &&
            <Pressable onClick={() => setShowPossibility(prev => !prev)}>
              <FadeSwitchWrapper switchKey={showPossibility}>
                {!showPossibility
                  ? <Possibility horizontalAlign="center" verticalAlign="center">
                    <Arm themeFont={theme.fonts.i1}>
                      💪
                    </Arm>
                    <Spacing themeSpace={theme.space(4)} />
                    <Text
                      themeFont={theme.fonts.i2.override({ weight: 500 })}
                      themeTextColor={theme.colors.white}>
                      <Translated id="app.main.possibility-window.button-title" />
                    </Text>
                  </Possibility>
                  : <WindowCloseButtonWrapper horizontalAlign="center" verticalAlign="center" small>
                    <Close size={16} themeColor={theme.colors.background} />
                  </WindowCloseButtonWrapper>}
              </FadeSwitchWrapper>
            </Pressable>}
        </FadeSwitchWrapper>

        <FadeSwitchWrapper switchKey={showPossibility}>
          {showPossibility && <PossibilityWindow>
            <Row horizontalAlign="center" verticalAlign="center">
              <Text themeFont={theme.fonts.i1.override({ size: theme.space(25), lineHeight: theme.space(30) })}>
                💪
              </Text>
              <Spacing themeSpace={theme.space(10)} />
              <Text
                transform="uppercase"
                themeFont={theme.fonts.i1}
                themeTextColor={theme.colors.white}>
                <Translated id="app.main.possibility-window.title" />
              </Text>
            </Row>
            <Spacing themeSpace={theme.space(12)} />
            <Text themeFont={theme.fonts.i6} themeTextColor={theme.colors.darkWhite}>
              <Translated id="app.main.possibility-window.desc" />
            </Text>

            <Spacing themeSpace={theme.space(12)} />
            <Line />
            <Spacing themeSpace={theme.space(12)} />

            <Row>
              <Step horizontalAlign="center" verticalAlign="center">
                <Text themeTextColor={theme.colors.grayDark} themeFont={theme.fonts.i2.override({ weight: 500 })}>1</Text>
              </Step>
              <Spacing themeSpace={theme.space(8)} />
              <Column>
                <Text transform="uppercase" themeFont={theme.fonts.i6.override({ weight: 500 })} themeTextColor={theme.colors.darkWhite}>
                  <Translated id="app.main.possibility-window.first-step-title" />
                </Text>
                <Spacing themeSpace={theme.space(4)} />
                <Text themeFont={theme.fonts.i7} themeTextColor={theme.colors.white}>
                  <Translated id="app.main.possibility-window.first-step-text" />
                </Text>
              </Column>
            </Row>

            <Spacing themeSpace={theme.space(12)} />
            <Line />
            <Spacing themeSpace={theme.space(12)} />

            <Row>
              <Step horizontalAlign="center" verticalAlign="center">
                <Text themeTextColor={theme.colors.grayDark} themeFont={theme.fonts.i2.override({ weight: 500 })}>2</Text>
              </Step>
              <Spacing themeSpace={theme.space(8)} />
              <Column>
                <Text transform="uppercase" themeFont={theme.fonts.i6.override({ weight: 500 })} themeTextColor={theme.colors.darkWhite}>
                  <Translated id="app.main.possibility-window.second-step-title" />
                </Text>
                <Spacing themeSpace={theme.space(4)} />
                <Text themeFont={theme.fonts.i7} themeTextColor={theme.colors.white}>
                  <Translated id="app.main.welcome-modal.third-step-text" values={{
                    time:
                      <>
                        <Time data-tooltip-id="possibility-time">
                          <Translated id="app.main.welcome-modal.time" />
                        </Time>
                        <ReactTooltip id="possibility-time" className="time-tooltip">
                          <TimeToolTip themeFont={theme.fonts.i7} themeTextColor={theme.colors.white} horizontalAlign="center"><Translated id="app.main.welcome-modal.tooltip" /></TimeToolTip>
                        </ReactTooltip>
                      </>,
                    unika:
                      <Link href={process.env.REACT_APP_UNIKA_DAPP} target="_blank" rel="noreferrer">
                        <Translated id="app.main.welcome-modal.unika" />
                      </Link>
                  }} />
                </Text>
              </Column>
            </Row>
          </PossibilityWindow>}
        </FadeSwitchWrapper>
      </PossibilityWrapper>

      <SupportWrapper window={showFeedback}>
        {showFeedback && <Overlay />}
        <FadeSwitchWrapper switchKey={status === 'denied' || status === 'participate' || status === 'success'}>
          {status === 'denied' || status === 'participate' || status === 'success'
            ? <Pressable onClick={() => setShowFeedback(prev => !prev)}>
              <FadeSwitchWrapper switchKey={showFeedback}>
                {showFeedback
                  ? < WindowCloseButtonWrapper horizontalAlign="center" verticalAlign="center">
                    <Close size={22} themeColor={theme.colors.background} />
                  </WindowCloseButtonWrapper>
                  : <Employee horizontalAlign="center" verticalAlign="center">
                    <img src={EmployeeAva} alt='Avatar' />
                    <Notfication horizontalAlign="center" verticalAlign="center">
                      <Text themeFont={theme.fonts.i5} themeTextColor={theme.colors.white}>
                        1
                      </Text>
                    </Notfication>
                  </Employee>}
              </FadeSwitchWrapper>
            </Pressable>
            : <SupportLink href="https://t.me/Unika_support" target="_blank" rel="noreferrer">
              <Row horizontalAlign="center" verticalAlign="center">
                <Support />
                {innerWidth > 768 && <>
                  <Spacing themeSpace={theme.space(8)} />
                  <Info transform='uppercase'
                    themeFont={theme.fonts.i1.override({ lineHeight: theme.space(20) })}
                    themeTextColor={theme.colors.white}>
                    <Translated id="app.main.support" />
                  </Info>
                </>}
              </Row>
            </SupportLink>}
        </FadeSwitchWrapper>

        <FadeSwitchWrapper switchKey={showFeedback}>
          {showFeedback &&
            <EmployeeSupportWindow>
              <Text themeFont={theme.fonts.i7} themeTextColor={theme.colors.white}>
                <Translated id="app.main.suppory-window.top" />
              </Text>
              <Spacing themeSpace={theme.space(12)} />
              <Text themeFont={theme.fonts.i7} themeTextColor={theme.colors.white}>
                <Translated id="app.main.suppory-window.middle" />
              </Text>
              <Spacing themeSpace={theme.space(12)} />
              <Text themeFont={theme.fonts.i7} themeTextColor={theme.colors.white}>
                <Translated id="app.main.suppory-window.bottom" />
              </Text>
              <Spacing themeSpace={theme.space(12)} />
              <Text themeFont={theme.fonts.i7} themeTextColor={theme.colors.white}>
                <Translated id="app.main.suppory-window.contact" values={{
                  email:
                    <Link href="mailto:max@unika.network" target="_blank" rel="noreferrer">
                      max@unika.network
                    </Link>,
                  telegram:
                    <Link href="https://t.me/max_holub" target="_blank" rel="noreferrer">
                      <Translated id="app.main.suppory-window.telemram" />
                    </Link>
                }} />
              </Text>

              <Spacing themeSpace={theme.space(10)} />
              <Line />
              <Spacing themeSpace={theme.space(10)} />

              <Row horizontalAlign="center" verticalAlign="center">
                <img src={EmployeeAva} alt='Avatar' />
                <Spacing themeSpace={theme.space(10)} />
                <Column>
                  <Text themeFont={theme.fonts.i6} themeTextColor={theme.colors.darkWhite}>
                    <Translated id="app.main.suppory-window.name" />
                  </Text>
                  <Text themeFont={theme.fonts.i6} themeTextColor={theme.colors.darkWhite}>
                    <Translated id="app.main.suppory-window.position" />
                  </Text>
                </Column>
              </Row>
            </EmployeeSupportWindow>}
        </FadeSwitchWrapper>
      </SupportWrapper>

      <Cookie />
    </HeadedView>
  )
});
