import { ReactNode, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useGeneratorCallback } from 't-hooks';
import { Task } from 't-tasks';

export type ExtendedProps<T> = {
  props: T;
  transitionState: TransitionStatus;
  transitionDuration: number;
};

export const Optional = <T extends {}>({
  children,
  props = null,
  transitionDuration = 300,
  className,
}: {
  children(p: ExtendedProps<T>): ReactNode;
  props?: T | null;
  transitionDuration?: number;
  className?: string;
}) => {
  const staggered = useRef(props);

  if (props) {
    staggered.current = props;
  }

  const render = useCallback(
    (transitionState: TransitionStatus) => staggered.current !== null && children({ props: staggered.current, transitionState, transitionDuration }),
    [children, transitionDuration, staggered],
  );

  const nodeRef = useRef<HTMLElement>();

  return (
    <CSSTransition in={props !== null} timeout={transitionDuration} mountOnEnter={true} unmountOnExit={true} classNames={className} nodeRef={nodeRef}>
      {render}
    </CSSTransition>
  );
};

export type ImperativeExtendedProps<T> = {
  props: T;
  close: () => void;
  transitionState: TransitionStatus;
  transitionDuration: number;
};

export type OptionalHandle<T extends {} = {}> = {
  open(props: T): void;
  close(): void;
};

const useOptionalProps = <T extends {}>(
  ref: Ref<OptionalHandle<T>> | undefined, initial: T | null, transitionDuration: number, onProps: ((props: T | null) => void) | undefined,
) => {
  const [props, setProps] = useState<T | null>(initial ?? null);

  const opening = useRef<boolean>(false);

  const open = useGeneratorCallback(
    function* (p: T) {
      opening.current = true;

      setProps(p);

      yield* Task.timeout(transitionDuration).generator();

      opening.current = false;
    },
    [setProps, transitionDuration],
  );

  const close = useCallback(() => {
    if (!opening.current) {
      setProps(null);
    }
  }, [setProps]);

  useImperativeHandle(ref, () => ({ open, close }), [open, close]);

  useEffect(() => {
    onProps?.(props);
  }, [props, onProps]);

  return [props, open, close] as const;
};

export const ImperativeOptional = <T extends {}>({
  children,
  initial = null,
  optionalRef,
  transitionDuration = 300,
  onProps,
}: {
  children(p: ImperativeExtendedProps<T>): ReactNode;
  initial?: T | null;
  optionalRef?: Ref<OptionalHandle<T>>;
  onProps?: (props: T | null) => void;
  transitionDuration?: number;
}) => {
  const [props, , close] = useOptionalProps(optionalRef, initial, transitionDuration, onProps);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const render = useCallback((extended: ExtendedProps<T>) => children({ ...extended, close }), [children, close]);

  return (
    <Optional props={props} transitionDuration={transitionDuration}>
      {render}
    </Optional>
  );
};

type Open<T extends {}> = T extends { [key: string]: never } ? (params?: T) => void : (params: T) => void;

export const useOptionalRef = <T extends {}>() => {
  const ref = useRef<OptionalHandle<T>>(null);

  const open = useCallback((p?: T) => {
    ref.current?.open(p ?? ({} as T));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) as Open<T>;

  const close = useCallback(() => {
    ref.current?.close();
  }, []);

  return [ref, open, close] as const;
};
