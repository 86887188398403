import { useMemo } from 'react';
import { createService } from '@spatium/wallet-kit';
import { walletConnectProvider } from '@web3modal/ethereum';
import { configureChains, createClient } from 'wagmi';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { publicProvider } from 'wagmi/providers/public'

import { chains } from 'lib/chains';

function useWalletConnectorServiceProvider() {
  const walletConnectProjectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? '';

  const { provider } = configureChains(chains, [
    walletConnectProvider({ projectId: walletConnectProjectId }),
    // alchemyProvider({ apiKey: 'yourAlchemyApiKey' }),
    publicProvider()
  ]);

  const wagmiClient = createClient({
    autoConnect: true,
    connectors: [
      new MetaMaskConnector({
        chains,
      }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
          version: '1',
          // projectId: walletConnectProjectId,
        },
      }),
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: 'Unika',
        },
      }),
    ],
    provider,
  });

  return useMemo(() => ({
    walletConnectProjectId,
    wagmiClient,
  }), [walletConnectProjectId, wagmiClient]);
}

export const [WalletConnectorServiceProvider, useWalletConnectorService] = createService(
  useWalletConnectorServiceProvider,
  {
    walletConnectProjectId: '',
    wagmiClient: {} as any,
  },
);
