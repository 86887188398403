import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type FuseProps = {
  size?: number;
};

export const Fuse: FC<FuseProps & ClassNameProps> = memo(({
  size = 20,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2168_6350)">
      <path d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z" fill="url(#paint0_linear_2168_6350)" />
      <mask id="mask0_2168_6350" maskUnits="userSpaceOnUse" x="3" y="11" width="14" height="6">
        <path d="M3.81873 11.6875H16.2172V16.4653H3.81873V11.6875Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2168_6350)">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0059 14.1194C9.56901 14.1194 9.16307 14.027 8.83167 13.8522L5.26323 11.9688L4.19401 12.6173C3.68589 12.9255 3.69464 13.398 4.21385 13.672L9.14917 16.2767C9.38885 16.4031 9.69526 16.4655 10.0059 16.4655C10.3684 16.4655 10.7364 16.3806 11.0103 16.2147L15.8417 13.2839C16.3499 12.9756 16.341 12.5031 15.822 12.2292L14.7959 11.6875L11.3631 13.77C10.9917 13.995 10.5096 14.1194 10.0059 14.1194Z" fill="url(#paint1_linear_2168_6350)" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.8538 9.11125L14.997 8.65234L13.0019 9.88031L12.6594 10.0909L12.317 10.3019L11.3831 10.8766C11.0106 11.1059 10.5272 11.2322 10.0222 11.2322C9.58422 11.2322 9.17719 11.1381 8.84501 10.9603L7.77485 10.3872L7.42079 10.1978L7.06657 10.0081L5.09501 8.95203L4.19501 9.50609C3.68563 9.81969 3.69422 10.3002 4.21485 10.5789L5.24376 11.1298L5.59797 11.3195L5.95188 11.5092L9.16329 13.2289C9.40344 13.3575 9.71094 13.4206 10.0222 13.4206C10.3856 13.4206 10.7547 13.3347 11.0291 13.1658L14.1166 11.2655L14.4591 11.0547L14.8014 10.8439L15.8736 10.1842C16.3831 9.87062 16.3742 9.39 15.8538 9.11125Z" fill="url(#paint2_linear_2168_6350)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.8538 6.30016L10.9054 3.69641C10.665 3.56984 10.3579 3.50781 10.0463 3.50781C9.68286 3.50781 9.31379 3.5925 9.03942 3.75844L4.1952 6.68812C3.68551 6.99625 3.69426 7.46844 4.21489 7.74234L5.07161 8.19312L5.42583 8.37953L5.77989 8.56594L7.75145 9.60344L8.10567 9.78953L8.45989 9.97609L9.16333 10.3464C9.40348 10.4727 9.71098 10.5347 10.0222 10.5347C10.3857 10.5347 10.7547 10.45 11.0291 10.2841L11.6088 9.93359L11.9513 9.72641L12.2935 9.51937L14.2888 8.31281L14.6311 8.10578L14.9735 7.89859L15.8736 7.35437C16.3832 7.04625 16.3743 6.57406 15.8538 6.30016Z" fill="url(#paint3_linear_2168_6350)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2168_6350" x1="4.44444" y1="1.94444" x2="16.9444" y2="17.2222" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0E2D48" />
        <stop offset="1" stopColor="#0D151E" />
      </linearGradient>
      <linearGradient id="paint1_linear_2168_6350" x1="10.0179" y1="3.92422" x2="10.0179" y2="16.4656" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B3F9BA" />
        <stop offset="1" stopColor="#F6FB8C" />
      </linearGradient>
      <linearGradient id="paint2_linear_2168_6350" x1="10.0342" y1="3.93406" x2="10.0342" y2="16.1773" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B1FFBF" />
        <stop offset="1" stopColor="#FFF16D" />
      </linearGradient>
      <linearGradient id="paint3_linear_2168_6350" x1="10.0343" y1="3.50781" x2="10.0343" y2="16.2127" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B3F9BA" />
        <stop offset="1" stopColor="#F6FB8C" />
      </linearGradient>
      <clipPath id="clip0_2168_6350">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
));
