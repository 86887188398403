import axios from 'axios';

type HttpOptions = {
  url: string;
  method: 'GET' | 'POST';
  headers?: Record<string, string>;
  params?: Record<string, string>;
  data?: Record<string, string>;
};

export const request = async <T>(options: HttpOptions) => {
  try {
    const response = await axios.request<T>(options);

    if (response.status === 200) {
      return response.data as T;
    } else {
      console.error(response.data);
      throw Error(response.data?.toString() ?? 'unknown-error');
    }
  } catch (e: any) {
    console.error(e.response?.data ?? e);
    throw Error(e.response?.data?.message ?? (e.message === 'Network Error' ? 'bad-gateway' : 'unknown-error'));
  }
};

export const get = async <T>(url: string, params: Record<string, string>, headers?: Record<string, string>) => {
  return request<T>({
    method: 'GET',
    url,
    params,
    headers,
  });
};

export const post = async <T>(url: string, data: Record<string, any>, headers?: Record<string, string>) => {
  return request<T>({
    method: 'POST',
    url,
    headers: { 'Content-Type': 'application/json', ...headers },
    data,
  });
};
