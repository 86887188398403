import { FC, memo } from 'react';
import { Theme, theme } from '../../theme';
import { ClassNameProps, ThemeColor, ThemeColorProvider } from '@spatium/wallet-kit';

export type CloseProps = {
  size?: number;
  themeColor?: ThemeColor<Theme>;
};

export const Close: FC<CloseProps & ClassNameProps> = memo(({
  size = 22,
  themeColor = theme.colors.darkWhite,
  className,
  style,
}) => (
  <ThemeColorProvider themeColor={themeColor}>
    {(color) => (
      <svg className={className} style={style} width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.701996 21.9936C0.31525 21.6033 0.31525 20.9705 0.701996 20.5802L20.3094 0.792723C20.6962 0.402425 21.3232 0.402426 21.7099 0.792724C22.0967 1.18302 22.0967 1.81582 21.7099 2.20612L2.10252 21.9936C1.71578 22.3839 1.08874 22.3839 0.701996 21.9936Z" fill={color} />
        <path d="M0.290059 1.00638C0.676805 0.616084 1.30384 0.616084 1.69059 1.00638L21.298 20.7939C21.6848 21.1842 21.6847 21.817 21.298 22.2073C20.9113 22.5976 20.2842 22.5976 19.8975 22.2073L0.290059 2.41977C-0.0966868 2.02948 -0.096686 1.39668 0.290059 1.00638Z" fill={color} />
      </svg>
    )}
  </ThemeColorProvider>
));
