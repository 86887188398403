import { useCallback, useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { useGeneratorEffect } from 't-hooks';
import { Task } from 't-tasks';
import { BigNumber } from 'ethers';
import { Connector, useAccount, useConnect, useDisconnect, useNetwork, useSwitchNetwork, useSigner } from 'wagmi';
import { screen, useOptionalRef } from '@spatium/wallet-kit';
import { Unika, Operation, VerificationStatus } from '@unika/sdk';

import { useShowSnack } from 'lib/snack';
import { hexToString } from 'lib/utils';
import { ChainObject, chains } from 'lib/chains';

import {
  AirdropServiceExample,
  AirdropServiceExample__factory,
} from 'contracts/typechain-types';
import contractAddress from 'contracts/contract-address.json';

import { MainProps } from './main.props';
import { MainView } from './main.view';

export type WhitelistEntry = {
  address: string;
  date: Date;
};

export type Status =
  'initial' |
  'connecting' |
  'verified' |
  'outdated' |
  'not-verified' |
  'participate' |
  'signing' |
  'sending-service' |
  'approving' |
  'sending-unika' |
  'checking' |
  'success' |
  'denied';

export const Main = screen<MainProps>(() => {
  const showSnack = useShowSnack();
  const [AirdropServiceContract, setAirdropServiceContract] = useState<AirdropServiceExample>();

  const statusRef = useRef<Status>();
  const [operation, setOperation] = useState<Operation>();
  const [requestId, setRequestId] = useState<BigNumber>();
  const [status, setStatus] = useState<Status>('initial');

  const [whitelist, setWhitelist] = useState<WhitelistEntry[]>([]);
  const [welcomeModalRef, openWelcomeModal, closeWelcomeModal] = useOptionalRef();
  const [infoModalRef, openInfoModal, closeInfoModal] = useOptionalRef();
  const [confirmSharingModalRef, openConfirmSharingModal, closeConfirmSharingModal] = useOptionalRef();
  const [metamaskModalRef, openMetamaskModal, closeMetamaskModal] = useOptionalRef();
  const [whitelistModalRef, openWhitelistModal, closeWhitelistModal] = useOptionalRef();
  const [errorModalRef, openErrorModal, closeErrorModal] = useOptionalRef();

  const { connect, error: connectError, pendingConnector } = useConnect();
  const { disconnect, error: disconnectError } = useDisconnect();
  const { address, isConnected } = useAccount({
    onConnect: ({ isReconnected }) => {
      !isReconnected && showSnack('wallet-connected');
    },
  });
  const { switchNetwork, error: switchError } = useSwitchNetwork({
    onSuccess(data) {
      setSelectedChain(chains.find((x) => x.id === data.id) ?? chains[0]);
    },
  });

  const { data: signer } = useSigner();
  const { chain: connectedChain } = useNetwork();

  const [selectedChain, setSelectedChain] = useState<ChainObject>(chains[0]);

  const onSelectChain = useCallback((selected: ChainObject) => {
    if (isConnected && switchNetwork) {
      switchNetwork(selected.id);
    } else {
      setSelectedChain(selected);
    }
  }, [isConnected, switchNetwork]);

  useEffect(() => {
    if (!connectError) {
      return;
    } else if ((connectError as any)?.code === 4001) {
      showSnack('operation-canceled');
    } else if (connectError.name === 'ConnectorNotFoundError' && pendingConnector?.id === 'metaMask') {
      !isMobile ? openMetamaskModal() : showSnack('metamask-not-installed');
    } else {
      console.error('connectError:', connectError);
      showSnack('unknown-error');
    }

    setStatus('initial');
  }, [connectError, openMetamaskModal, showSnack, pendingConnector]);

  useEffect(() => {
    if (!switchError) {
      return;
    } else {
      console.error('switchError:', switchError);
      showSnack('unknown-error');
    }
  }, [switchError, showSnack]);

  useEffect(() => {
    if (!disconnectError) {
      return;
    } else {
      console.error('disconnectError:', disconnectError);
      showSnack('unknown-error');
    }
  }, [disconnectError, showSnack]);

  useEffect(() => {
    console.log('status:', status);
    statusRef.current = status
  }, [status]);

  useEffect(() => {
    if (localStorage.getItem('instructed') !== 'true') {
      setTimeout(openWelcomeModal, 1000);
    }
  }, [openWelcomeModal]);

  useGeneratorEffect(function* () {
    if (!signer || !address) {
      return;
    }

    setStatus('connecting');

    // debounce due to wagmi useSigner bug
    yield* Task.timeout(100).generator();

    try {
      yield* Task.promiseGenerator(Unika.init({
        signer,
        onVerificationStatus: (status: VerificationStatus) => {
          switch (status) {
            case 'verification-queued':
            case 'verification-position-updated':
            case 'verification-assigned':
            case 'verification-claimed':
              setStatus('checking'); break;
            case 'verification-confirm':
            case 'verification-reject':
              console.log('verification-result:', status); break;
            case 'verification-timeout':
            case 'verification-error':
              openErrorModal(); break;
            default:
              console.log('unika-status:', status); break;
          }
        }
      }));

      const identificationTimestamp = yield* Task.promiseGenerator(Unika.getIdentificationTimestamp(address));
      console.log('identificationTimestamp:', identificationTimestamp);

      const { status, position, claimTimestamp } = yield* Task.promiseGenerator(Unika.getVerificationRequestData(address));
      console.log('verification-status:', status);
      console.log('position:', position);
      console.log('claimTimestamp:', claimTimestamp);

      if (status === 'queued' || status === 'assigned' || status === 'claimed') {
        setStatus('checking');
      } else if (identificationTimestamp !== 0 && moment(identificationTimestamp * 1000).add(24, 'hours').isBefore()) {
        setStatus('outdated');
      } else if (identificationTimestamp !== 0) {
        setStatus('verified');
      } else {
        setStatus('not-verified');
      }

      setAirdropServiceContract(AirdropServiceExample__factory.connect(contractAddress.AirdropServiceExample, signer));
    } catch (e) {
      console.error(e);
      showSnack('unknown-error');
    }
  }, [address, showSnack, signer]);

  useEffect(() => {
    if (!address || !AirdropServiceContract) {
      return;
    }

    AirdropServiceContract.on('AirdropClaimRequestAdded', (operation: Operation, requestId: BigNumber, clientAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.log('AirdropClaimRequestAdded', operation, requestId, clientAddress);

        setOperation(operation);
        setRequestId(requestId);
        setStatus('approving');
        openConfirmSharingModal();
      }
    });

    AirdropServiceContract.on('AirdropClaimRequestConfirmed', (requestId: BigNumber, clientAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.log('AirdropClaimRequestConfirmed', requestId, clientAddress);
        setStatus('success');
      }
    });

    AirdropServiceContract.on('AirdropClaimRequestDenied', (requestId: BigNumber, clientAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.log('AirdropClaimRequestDenied', requestId, clientAddress);
        setStatus('denied');
      }
    });

    AirdropServiceContract.on('AirdropClaimRequestError', (requestId: BigNumber, clientAddress: string, error: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.error('AirdropClaimRequestError', requestId, clientAddress, error);
        setStatus('verified');
        openErrorModal();
      }
    });

    return () => {
      AirdropServiceContract.removeAllListeners();
    }
  }, [address, AirdropServiceContract, openErrorModal, showSnack, openConfirmSharingModal]);

  const disconnectWallet = useCallback(async () => {
    setStatus('initial');
    setOperation(undefined);
    setRequestId(undefined);

    disconnect();
    Unika.deinit();
  }, [disconnect]);

  useEffect(() => {
    if (!connectedChain) {
      return;
    } else if (!chains.find((x) => x.id === connectedChain.id)) {
      disconnectWallet();
      showSnack('unsupported-chain');
    }
  }, [connectedChain, disconnectWallet, showSnack]);

  const connectWallet = useCallback(async (connector: Connector, chainId: number) => {
    try {
      setStatus('connecting');

      connect({
        connector,
        chainId,
      });
    } catch (e: any) {
      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        showSnack('operation-canceled');
      } else if (e?.message === 'metamask-not-installed') {
        openMetamaskModal();
      } else {
        console.error(e);
        showSnack('unknown-error');
      }

      disconnectWallet();
    }
  }, [connect, disconnectWallet, showSnack, openMetamaskModal]);

  const initializeClaimRequest = useCallback(async () => {
    try {
      if (!address || !AirdropServiceContract) {
        throw Error('wallet-not-connected');
      }

      const _whitelist = await AirdropServiceContract.getWhitelistAddresses();

      if (_whitelist.some((x) => x.address_.toLowerCase() === address.toLowerCase())) {
        setStatus('participate');
        return;
      }

      setStatus('signing');

      const tx = await AirdropServiceContract.initializeClaimRequest();

      showSnack('tx-sent');
      setStatus('sending-service');

      await tx.wait();

      console.log('initialize-claim-complete');
    } catch (e: any) {
      setStatus('verified');

      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        showSnack('operation-canceled');
      } else if (e?.code === -32603 && e.data?.message?.startsWith('Reverted')) {
        console.error('Error:', hexToString(e.data.message.replaceAll('Reverted ', '')));
      } else {
        console.error(e);
        showSnack('unknown-error');
      }
    }
  }, [address, AirdropServiceContract, showSnack]);

  const onUnika = useCallback(() => {
    window.open(process.env.REACT_APP_UNIKA_DAPP, '_self');
  }, []);

  const onConfirmSharing = useCallback(async () => {
    try {
      closeConfirmSharingModal();

      if (!address || !operation || !requestId || !AirdropServiceContract) {
        throw Error('wallet-not-connected');
      }

      setStatus('sending-unika');

      await Unika.sendVerificationRequest(operation, AirdropServiceContract.address, requestId.toString());
    } catch (e: any) {
      setStatus('verified');

      if (e?.code === 4001 || e.code === 'ACTION_REJECTED') {
        showSnack('operation-canceled');
      } else {
        console.error(e);
        showSnack('unknown-error');
      }
    }
  }, [closeConfirmSharingModal, address, operation, requestId, AirdropServiceContract, showSnack]);

  const onRejectSharing = useCallback(() => {
    setStatus('verified');
    closeConfirmSharingModal();
  }, [closeConfirmSharingModal]);

  const onCheckWhiteList = useCallback(async () => {
    try {
      if (!AirdropServiceContract) {
        throw Error('wallet-not-connected');
      }

      const _whitelist = await AirdropServiceContract.getWhitelistAddresses();

      setWhitelist(_whitelist?.map((x) => ({
        address: x.address_,
        date: new Date(x.timestamp.toNumber() * 1000),
      })).sort((a, b) => a > b ? 1 : -1));

      openWhitelistModal();
    } catch (e) {
      console.error(e);
      showSnack('unknown-error');
    }
  }, [AirdropServiceContract, openWhitelistModal, showSnack]);

  const onCloseWhiteList = useCallback(() => {
    closeWhitelistModal();
  }, [closeWhitelistModal]);

  const onErrorTryAgain = useCallback(() => {
    closeErrorModal();
  }, [closeErrorModal]);

  const onInstallMetamask = useCallback(() => {
    window.open('https://metamask.io/download');
  }, []);

  const onMetamaskTryAgain = useCallback(() => {
    closeMetamaskModal();
    window.location.reload();
  }, [closeMetamaskModal]);

  const onCloseMetamaskModal = useCallback(() => {
    closeMetamaskModal()
  }, [closeMetamaskModal]);

  const onCloseWelcome = useCallback(() => {
    localStorage.setItem('instructed', 'true');
    closeWelcomeModal();
  }, [closeWelcomeModal]);

  const onOpenInfoModal = useCallback(() => {
    openInfoModal();
  }, [openInfoModal]);

  const onCloseInfoModal = useCallback(() => {
    closeInfoModal();
  }, [closeInfoModal]);

  return (
    <MainView
      status={status}
      address={address}
      selectedChain={selectedChain}
      connectedChain={connectedChain}
      whitelist={whitelist}
      infoModalRef={infoModalRef}
      errorModalRef={errorModalRef}
      welcomeModalRef={welcomeModalRef}
      whitelistModalRef={whitelistModalRef}
      metamaskModalRef={metamaskModalRef}
      confirmSharingModalRef={confirmSharingModalRef}
      onConnectWallet={connectWallet}
      onSelectChain={onSelectChain}
      onDisconnectWallet={disconnectWallet}
      initializeClaimRequest={initializeClaimRequest}
      onConfirmSharing={onConfirmSharing}
      onRejectSharing={onRejectSharing}
      onCheckWhitelist={onCheckWhiteList}
      onCloseWhiteListModal={onCloseWhiteList}
      onUnika={onUnika}
      onInstallMetamask={onInstallMetamask}
      onErrorTryAgain={onErrorTryAgain}
      onCloseWelcome={onCloseWelcome}
      onMetamaskTryAgain={onMetamaskTryAgain}
      onCloseMetamaskModal={onCloseMetamaskModal}
      onOpenInfoModal={onOpenInfoModal}
      onCloseInfoModal={onCloseInfoModal}
    />
  );
});
