/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import type * as airdropServiceExampleSol from "./AirdropServiceExample.sol";
export type { airdropServiceExampleSol };
export type { UnikaIdentification } from "./UnikaIdentification";
export type { UnikaIdentificationRelay } from "./UnikaIdentificationRelay";
export type { UnikaMedium } from "./UnikaMedium";
export type { UnikaMediumRelay } from "./UnikaMediumRelay";
export type { UnikaValidator } from "./UnikaValidator";
export * as factories from "./factories";
export type { AirdropServiceExample } from "./AirdropServiceExample.sol/AirdropServiceExample";
export { AirdropServiceExample__factory } from "./factories/AirdropServiceExample.sol/AirdropServiceExample__factory";
export type { IUnikaService } from "./AirdropServiceExample.sol/IUnikaService";
export { IUnikaService__factory } from "./factories/AirdropServiceExample.sol/IUnikaService__factory";
export { UnikaIdentification__factory } from "./factories/UnikaIdentification__factory";
export { UnikaIdentificationRelay__factory } from "./factories/UnikaIdentificationRelay__factory";
export { UnikaMedium__factory } from "./factories/UnikaMedium__factory";
export { UnikaMediumRelay__factory } from "./factories/UnikaMediumRelay__factory";
export { UnikaValidator__factory } from "./factories/UnikaValidator__factory";
