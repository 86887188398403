import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type AvalancheProps = {
  size?: number;
};

export const Avalanche: FC<AvalancheProps & ClassNameProps> = memo(({
  size = 20,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#E84142" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.19878 14.2191H5.30628C4.90878 14.2191 4.71253 14.2191 4.59253 14.1423C4.52931 14.101 4.47668 14.0455 4.43889 13.9801C4.40111 13.9147 4.37923 13.8414 4.37503 13.766C4.36753 13.6248 4.46566 13.4516 4.66253 13.1066L9.33253 4.86102C9.53128 4.51102 9.63253 4.33602 9.75878 4.27102C9.82636 4.23666 9.9011 4.21875 9.97691 4.21875C10.0527 4.21875 10.1275 4.23666 10.195 4.27102C10.3219 4.33602 10.4225 4.51102 10.6213 4.86102L11.5813 6.53977L11.5863 6.54852C11.8007 6.92352 11.9094 7.11477 11.9569 7.31477C12.01 7.53368 12.01 7.76211 11.9569 7.98102C11.9094 8.1829 11.8013 8.37477 11.5838 8.75602L9.13003 13.0998L9.12378 13.1104C8.90753 13.4891 8.79878 13.681 8.64628 13.826C8.48216 13.9832 8.28288 14.0989 8.06503 14.1635C7.86566 14.2191 7.64378 14.2191 7.19878 14.2191ZM11.9757 14.2191H14.6857C15.0857 14.2191 15.2869 14.2191 15.4069 14.1404C15.4706 14.0989 15.5236 14.0429 15.5614 13.9769C15.5992 13.911 15.6208 13.837 15.6244 13.761C15.6313 13.6241 15.5357 13.4585 15.3475 13.1329L15.3275 13.0991L13.97 10.7729L13.9544 10.7466C13.7638 10.4235 13.6669 10.2604 13.5438 10.1973C13.4767 10.163 13.4025 10.1452 13.3272 10.1452C13.2519 10.1452 13.1777 10.163 13.1107 10.1973C12.9857 10.2623 12.8857 10.4329 12.6869 10.7754L11.3338 13.1016L11.3294 13.1098C11.1313 13.4523 11.0319 13.6229 11.0394 13.7635C11.0436 13.8393 11.0654 13.913 11.1032 13.9787C11.1409 14.0445 11.1936 14.1005 11.2569 14.1423C11.3744 14.2191 11.5757 14.2191 11.9757 14.2191Z" fill="white" />
  </svg>
));
