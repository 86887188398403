import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type CoinbaseLogoProps = {
  size?: number;
};

export const CoinbaseLogo: FC<CoinbaseLogoProps & ClassNameProps> = memo(({
  size = 20,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2041_8249)">
      <path d="M60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30Z" fill="#0052FF" />
      <path d="M30.0086 40.5469C24.1815 40.5469 19.4617 35.8271 19.4617 30C19.4617 24.1729 24.1815 19.4531 30.0086 19.4531C35.2293 19.4531 39.5623 23.2588 40.3973 28.2422H51.0233C50.1268 17.4141 41.0652 8.90625 30.0086 8.90625C18.363 8.90625 8.91479 18.3545 8.91479 30C8.91479 41.6455 18.363 51.0938 30.0086 51.0938C41.0652 51.0938 50.1268 42.5859 51.0233 31.7578H40.3885C39.5535 36.7412 35.2293 40.5469 30.0086 40.5469Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_2041_8249">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
));
