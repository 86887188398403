import { FC, memo, useRef, cloneElement } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

export type SwitchWrapperProps = {
  switchKey: string | number | boolean | null;
  transitionDuration?: number;
  classNames: string;
};

const normalizeKey = (key: SwitchWrapperProps['switchKey']) => {
  if (typeof key === 'boolean') {
    return key ? 'true' : 'false';
  }

  return key;
};

export const SwitchWrapper: FC<SwitchWrapperProps> = memo(({ switchKey, transitionDuration = 300, children, classNames }) => {
  const nodeRef = useRef<HTMLElement>();

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={normalizeKey(switchKey)}
        nodeRef={nodeRef}
        timeout={transitionDuration}
        classNames={classNames}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {cloneElement(children as any, { ref: nodeRef })}
      </CSSTransition>
    </SwitchTransition>
  );
});
