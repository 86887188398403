import styled, { createGlobalStyle } from 'styled-components';

export const ScreenRoot = styled.div`
  position: absolute;

  right: 0;
  left: 0;
  bottom: 0;
  top: 0;

  overflow: hidden;
  background: transparent;

  & > div, & > div > * {
    width: 100%;
    height: 100%;
  }
`;

export const TransitionWrapper = styled.div`
  position: absolute;

  right: 0;
  left: 0;
  bottom: 0;
  top: 0;

  overflow: hidden;
  background: transparent;

  & > div, & > div > * {
    width: 100%;
    height: 100%;
  }
`;

export const ModalRoot = styled.div`
  position: absolute;

  right: 0;
  left: 0;
  bottom: 0;
  top: 0;

  pointer-events: none;
  overflow: hidden;
  z-index: 100;

  & > * {
    width: 100%;
    height: 100%;
  }
`;

export const SnackRoot = styled.div`
  position: fixed;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 35px 20px;
  box-sizing: border-box;

  pointer-events: none;
  z-index: 101;
`;

type InsetProps = {
  insetTop?: number;
  insetBottom?: number;
  insetLeft?: number;
  insetRight?: number;
};

export const GlobalStyle = createGlobalStyle<InsetProps>`
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Inter-Light'), local('sans-serif'), url('./fonts/Inter/Inter-Light.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Inter-Regular'), local('sans-serif'), url('./fonts/Inter/Inter-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Inter-Medium'), local('sans-serif'), url('./fonts/Inter/Inter-Medium.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Inter-SemiBold'), local('sans-serif'), url('./fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Inter-Bold'), local('sans-serif'), url('./fonts/Inter/Inter-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Inconsolata-Regular'), local('sans-serif'), url('./fonts/Inconsolata/Inconsolata-Regular.ttf') format('truetype');
  }
  
  body {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    margin: 0;
    background: #171717;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;

    --safe-area-inset-top-default: ${({ insetTop = '0px' }) => insetTop};
    --safe-area-inset-bottom-default: ${({ insetBottom = '0px' }) => insetBottom};
    --safe-area-inset-left-default: ${({ insetLeft = '0px' }) => insetLeft};
    --safe-area-inset-right-default: ${({ insetRight = '0px' }) => insetRight};

    * {
      box-sizing: border-box;
    }

    .time-tooltip {
      --rt-opacity: 1;
      border-radius: 10px;
      filter: drop-shadow(0px 7px 30px rgba(0, 0, 0, 0.8));
      background: #262429;
    }
  }

  #root {
    width: 100%;
    height: 100%;
    position: relative;
  }

  h1 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
  }

  .cookie__container {
    width: 100%;
    padding: 16px 0px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background: #000000;
    position: fixed;
    bottom: 0;
    z-index: 50;
  }

  .cookie__text {
    margin-left: 40px;
    margin-right: 12px;

    a {
      color: #FFFFFF;
      text-decoration: underline;
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      margin-left: 20px;
    }
  }

  .cookie__btn-wrapper {
    margin-right: 40px;

    @media screen and (max-width: 768px) {
      margin-right: 20px;
    }
  }

  .cookie__btn {
    width: 97px;
    height: 33px;
    border: none;
    border-radius: 8px;
    background: linear-gradient(117.46deg, #003DB3 -16.2%, #4123BB 16.75%, #4B00C6 49.51%, #5E36CF 82.27%, #7018C8 117.62%);
    position: relative;
    z-index: 1;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 8px;
      background: linear-gradient(117.46deg, #004EE6 -16.2%, #5635D9 16.75%, #5F00FA 49.51%, #7E5ED9 82.27%, #8A2DE6 117.62%);
      transition: opacity 0.3s linear;
      z-index: -1;
      opacity: 0;
    }

    &:hover::before {
      opacity: 1;
    }
  }
`;
