import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Translated } from '@spatium/wallet-kit';
import CookieConsent from 'react-cookie-consent';

import { Text, theme } from 'theme';
import { FadeSwitchWrapper } from 'components/fade-switch-wrapper';

export type CookieProps = {};

export const Cookie: FC<CookieProps> = memo(() => {
  const [showCookie, setShowCookie] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowCookie(!localStorage.getItem('cookies'));
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const privacyLink = useMemo(() => `${process.env.REACT_APP_UNIKA_WEBSITE}/privacy-policy.pdf`, []);
  const termsLink = useMemo(() => `${process.env.REACT_APP_UNIKA_WEBSITE}/terms-of-use.pdf`, []);

  const onAccept = useCallback(() => {
    localStorage.setItem('cookies', 'accepted');
    setShowCookie(false);
  }, []);

  return (
    <FadeSwitchWrapper switchKey={showCookie} className="cookie">
      {showCookie && (
        <CookieConsent
          disableStyles
          onAccept={onAccept}
          expires={365}
          buttonText={<Translated id="app.main.cookie-agree" />}
          containerClasses="cookie__container"
          contentClasses="cookie__text"
          buttonWrapperClasses="cookie__btn-wrapper"
          buttonClasses="cookie__btn"
          visible={showCookie ? 'show' : 'hidden'}
        >
          <Text themeFont={theme.fonts.i2.override({ weight: 500, lineHeight: theme.space(18) })} themeTextColor={theme.colors.white}>
            <Translated id="app.main.cookie-banner"
              values={{
                privacy: <a href={privacyLink} target="_blank" rel="noreferrer"><Translated id="app.main.privacy" /></a>,
                terms: <a href={termsLink} target="_blank" rel="noreferrer"><Translated id="app.main.terms" /></a>,
              }} />
          </Text>
        </CookieConsent>
      )}
    </FadeSwitchWrapper>
  );
});
