import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type CeloProps = {
  size?: number;
};

export const Celo: FC<CeloProps & ClassNameProps> = memo(({
  size = 20,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2168_6497)">
      <path d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z" fill="#2E3338" />
      <path d="M8.68422 14.9495C10.683 14.9495 12.3027 13.3502 12.3027 11.3764C12.3027 9.40266 10.683 7.80312 8.68422 7.80312C6.68547 7.80312 5.06578 9.40266 5.06578 11.3764C5.06578 13.3502 6.68547 14.9495 8.68438 14.9495H8.68422ZM8.68422 16.2489C5.95922 16.2489 3.75 14.0673 3.75 11.3764C3.75 8.68547 5.95922 6.50391 8.68422 6.50391C11.4092 6.50391 13.6184 8.68547 13.6184 11.3764C13.6184 14.0673 11.4092 16.2489 8.68422 16.2489Z" fill="#FBCC5C" />
      <path d="M11.3158 12.352C13.3146 12.352 14.9342 10.7525 14.9342 8.77875C14.9342 6.80516 13.3146 5.20563 11.3158 5.20563C9.31722 5.20563 7.69737 6.805 7.69737 8.77875C7.69737 10.7525 9.31722 12.352 11.3158 12.352ZM11.3158 13.6512C8.59081 13.6512 6.38159 11.4697 6.38159 8.77875C6.38159 6.08781 8.59097 3.90625 11.316 3.90625C14.0408 3.90625 16.25 6.08781 16.25 8.77875C16.25 11.4697 14.0408 13.6512 11.3158 13.6512Z" fill="#35D07F" />
      <path d="M11.4789 13.6502C11.8206 13.2419 12.0657 12.7618 12.1961 12.2456C12.7166 12.1173 13.2023 11.8755 13.6185 11.5375C13.5999 12.1346 13.4696 12.723 13.2344 13.2722C12.6772 13.5039 12.0823 13.632 11.4791 13.6503L11.4789 13.6502ZM7.80394 7.90844C7.28343 8.03677 6.79773 8.27858 6.38159 8.61656C6.40019 8.01985 6.53057 7.43192 6.76597 6.88328C7.32333 6.65195 7.91797 6.52342 8.52112 6.50391C8.17951 6.91221 7.93437 7.3923 7.80394 7.90844Z" fill="#ECFF8F" />
    </g>
    <defs>
      <clipPath id="clip0_2168_6497">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
));
