import { ReactNode, useCallback } from 'react';
import { Translated, useShowSnack as useGenericShowSnack } from '@spatium/wallet-kit';

export const variantList = ['failure', 'success'] as const;
export type Variant = typeof variantList[number];

export const snackList = [
  'wallet-connected',
  'tx-sent',
  'approved',

  'operation-canceled',
  'metamask-not-installed',
  'unsupported-chain',
  'unknown-error',
] as const;
export type StandardSnack = typeof snackList[number];

export const standardSnacks: Record<StandardSnack, (values?: Record<string, any>) => {
  variant: Variant;
  message: ReactNode;
}> = {
  'wallet-connected': () => ({
    variant: 'success',
    message: <Translated id="snack.wallet-connected" />,
  } as const),
  'tx-sent': () => ({
    variant: 'success',
    message: <Translated id="snack.tx-sent" />,
  } as const),
  'approved': () => ({
    variant: 'success',
    message: <Translated id="snack.approved" />,
  } as const),

  'operation-canceled': () => ({
    variant: 'failure',
    message: <Translated id="snack.operation-canceled" />,
  } as const),
  'metamask-not-installed': () => ({
    variant: 'failure',
    message: <Translated id="snack.metamask-not-installed" />,
  } as const),
  'unsupported-chain': () => ({
    variant: 'failure',
    message: <Translated id="snack.unsupported-chain" />,
  } as const),
  'unknown-error': () => ({
    variant: 'failure',
    message: <Translated id="snack.unknown-error" />,
  } as const),
} as const;

export const useShowSnack = () => {
  const showSnack = useGenericShowSnack();

  return useCallback((snack: StandardSnack | {
    key: StandardSnack;
    values?: Record<string, any>;
    duration?: number;
    delay?: number;
  } | null) => showSnack(typeof snack === 'object' ? snack : { key: snack, duration: 5000 }), [showSnack]);
};
