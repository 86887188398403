import { ReactNode, Ref, useCallback } from 'react';

import { OptionalHandle, ImperativeExtendedProps, useBack } from '@spatium/wallet-kit';
import { ImperativeOptional } from './optional';

export const ModalWrapper = <T extends { }>({
  close,
  onBack,
  children,
  ...rest
}: ImperativeExtendedProps<T> & {
  children(p: ImperativeExtendedProps<T>): ReactNode;
  onBack?: () => void;
}) => {
  useBack(() => {
    onBack ? onBack() : close();
  }, [onBack, close]);

  return <>{children({ close, ...rest })}</>;
};

export const Modal = <T extends {}>({
  children,
  modalRef,
  initial = null,
  transitionDuration,
  onProps,
  onBack,
}: {
  children(p: ImperativeExtendedProps<T>): ReactNode;
  modalRef?: Ref<OptionalHandle<T>>;
  initial?: T | null;
  transitionDuration?: number;
  onProps?: (props: T | null) => void;
  onBack?: () => void;
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const render = useCallback((p: ImperativeExtendedProps<T>) => <ModalWrapper onBack={onBack} {...p}>{children}</ModalWrapper>, [children]);

  return (
    <ImperativeOptional optionalRef={modalRef} initial={initial} transitionDuration={transitionDuration} onProps={onProps}>
      {render}
    </ImperativeOptional>
  );
};
