import { ethers, BigNumber, Signer } from 'ethers';
import { Distortion, createUserAgent, DistortionSessionResult } from '@unika/distortion';

import { get, post } from './lib/axios';
import { initialize } from './lib/distortion';

import {
  UnikaIdentification,
  UnikaIdentification__factory,
  UnikaIdentificationRelay,
  UnikaIdentificationRelay__factory,
  UnikaMedium,
  UnikaMedium__factory,
  UnikaMediumRelay,
  UnikaMediumRelay__factory,
} from './contracts/typechain-types';
import contractAddress from './contracts/contract-address.json';

import en from './lang/en-us';

export enum Operation {
  __,
  NONE_IN_LIST,
  ALL_IN_LIST
}

export enum VerificationResult {
  __,
  ERROR,
  IDENTIFICATION_NOT_FOUND,
  CONFIRM,
  REJECT
}

export enum IdentificationResult {
  __,
  ERROR,
  FAILED,
  PASSED
}

export type IdentificationStatus =
  'identification-none' |
  'identification-queued' |
  'identification-position-updated' |
  'identification-assigned' |
  'identification-claimed' |
  'identification-processing' |
  'identification-canceled' |
  'identification-context-switched' |
  'identification-success' |
  'identification-timeout' |
  'identification-error'

export type VerificationStatus =
  'verification-none' |
  'verification-queued' |
  'verification-position-updated' |
  'verification-assigned' |
  'verification-claimed' |
  'verification-confirm' |
  'verification-reject' |
  'verification-timeout' |
  'verification-error';

export type StatusData = Partial<{
  position: number,
  claimTimestamp: number,
  identificationTimestamp: number,
}>;

type SessionTokenResponse = {
  status: string;
  message?: string;
  sessionToken: string;
};

type CheckLivenessResponse = {
  status: string;
  message?: string;
  scanResultBlob: string;
};

type ConnectionData = {
  url: string;
  nonce: string;
};

const deviceKeyIdentifier = 'dqXEaoUBiL15ZjYwqeF6wrwizZWUo0aC';
const publicFaceScanEncryptionKey = '-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5PxZ3DLj+zP6T6HFgzzkM77LdzP3fojBoLasw7EfzvLMnJNUlyRb5m8e5QyyJxI+wRjsALHvFgLzGwxM8ehzDqqBZed+f4w33GgQXFZOS4AOvyPbALgCYoLehigLAbbCNTkeY5RDcmmSI/sbp+s6mAiAKKvCdIqe17bltZ/rfEoL3gPKEfLXeN549LTj3XBp0hvG4loQ6eC1E1tRzSkfGJD4GIVvR+j12gXAaftj3ahfYxioBH7F7HQxzmWkwDyn3bqU54eaiB7f0ftsPpWMceUaqkL2DZUvgN0efEJjnWy5y1/Gkq5GGWCROI9XG/SwXJ30BbVUehTbVcD70+ZF8QIDAQAB\n-----END PUBLIC KEY-----';
const productionKey = '{"domains":"app.unika.network","expiryDate":"2023-04-10","key":"003046022100eca0f3d9e595e69b96c2058cf6872e7604d7ea85aaf6448a20d7cee9c772e704022100df0018a3ac999dfbb61e460cb7c57b80667957b451a85980eba24649a581378f"}';

// const coreProvider = new ethers.providers.JsonRpcProvider('http://127.0.0.1:8545');
const coreProvider = new ethers.providers.JsonRpcProvider('https://rpc.chiadochain.net');

let IdentificationContract: UnikaIdentification | undefined;
let IdentificationRelayContract: UnikaIdentificationRelay | undefined;
let MediumContract: UnikaMedium | undefined;
let MediumRelayContract: UnikaMediumRelay | undefined;

let signer: Signer | undefined;
let requestId: string | undefined;
let connectionData: ConnectionData | undefined;
let identificationError: string | undefined;

let verificationStatus: VerificationStatus = 'verification-none';
let identificationStatus: IdentificationStatus = 'identification-none';

type StatusCallback<T> = (status: T, data?: StatusData) => void;
let onIdentificationStatus: StatusCallback<IdentificationStatus> | undefined;
let onVerificationStatus: StatusCallback<VerificationStatus> | undefined;

const updateIdentificationStatus = (_status: IdentificationStatus, data?: StatusData) => {
  identificationStatus = _status;
  onIdentificationStatus?.(_status, data);
}

const updateVerificationStatus = (_status: VerificationStatus, data?: StatusData) => {
  verificationStatus = _status;
  onVerificationStatus?.(_status, data);
}

const getIdentificationTimestamp = async (address: string) => {
  if (!address) {
    throw Error('bad-parameters');
  } else if (!IdentificationContract) {
    throw Error('not-initialized');
  }

  const timestamp = await IdentificationContract.getIdentificationTimeByAddress(address);

  return timestamp.toNumber();
}

const getVerificationRequestData = async (address: string) => {
  if (!address) {
    throw Error('bad-parameters');
  } else if (!MediumContract) {
    throw Error('not-initialized');
  }

  const verificationRequests = await MediumContract.getVerificationRequests();
  const activeRequests = await MediumContract.getActiveRequests();

  const index = verificationRequests.findIndex((x) => x.clientAddress.toLowerCase() === address.toLowerCase());
  const activeRequest = activeRequests.find((x) => x.clientAddress.toLowerCase() === address.toLowerCase());

  const position = index !== -1 ? index + 1 : undefined;
  const claimTimestamp = activeRequest?.timestamp && !activeRequest.timestamp.isZero()
    ? activeRequest.timestamp.toNumber() + 600
    : undefined;

  const _status = claimTimestamp ? 'claimed' : activeRequest ? 'assigned' : position ? 'queued' : 'none';

  return {
    status: _status,
    position,
    claimTimestamp,
  }
}

const getIdentificationRequestData = async (address: string) => {
  if (!address) {
    throw Error('bad-parameters');
  } else if (!IdentificationContract) {
    throw Error('not-initialized');
  }

  const interactiveStageRequests = await IdentificationContract.getInteractiveStageRequests();
  const activeRequests = await IdentificationContract.getActiveRequests();

  const index = interactiveStageRequests.findIndex((x) => x.clientAddress.toLowerCase() === address.toLowerCase());
  const activeRequest = activeRequests.find((x) => x.clientAddress.toLowerCase() === address.toLowerCase());

  const position = index !== -1 ? index + 1 : undefined;
  const claimTimestamp = activeRequest?.timestamp && !activeRequest.timestamp.isZero()
    ? activeRequest.timestamp.toNumber() + 600
    : undefined;

  const _status = claimTimestamp ? 'claimed' : activeRequest ? 'assigned' : position ? 'queued' : 'none';

  requestId = activeRequest?.id?.toNumber().toString() ?? interactiveStageRequests[index]?.id?.toNumber().toString();
  connectionData = activeRequest?.connectionData;

  return {
    status: _status,
    position,
    claimTimestamp,
  }
};

const sendIdentificationRequest = async () => {
  if (!IdentificationContract) {
    throw Error('not-initialized');
  }

  const tx = IdentificationRelayContract
    ? await IdentificationRelayContract.initializeIdentification()
    : await IdentificationContract.initializeIdentification(ethers.constants.AddressZero);

  await tx.wait();
}

const sendVerificationRequest = async (operation: Operation, serviceAddress: string, serviceRequestId: string) => {
  if (!operation || !serviceAddress || !serviceRequestId) {
    throw Error('bad-parameters');
  } else if (!MediumContract) {
    throw Error('not-initialized');
  }

  const tx = MediumRelayContract
    ? await MediumRelayContract.initializeVerification(operation, serviceAddress, serviceRequestId)
    : await MediumContract.initializeVerification(operation, serviceAddress, serviceRequestId, ethers.constants.AddressZero, 0);

  await tx.wait();
}

export type UnikaInitProps = {
  signer: Signer;
  onIdentificationStatus?: StatusCallback<IdentificationStatus>;
  onVerificationStatus?: StatusCallback<VerificationStatus>;
}

const init = async (_: UnikaInitProps) => {
  deinit();

  const {
    signer: _signer,
    onIdentificationStatus: _onIdentificationStatus,
    onVerificationStatus: _onVerificationStatus,
  } = _;

  if (!_signer) {
    throw Error('bad-signer');
  }

  signer = _signer;
  onIdentificationStatus = _onIdentificationStatus;
  onVerificationStatus = _onVerificationStatus

  try {
    const coreNetwork = await coreProvider.getNetwork();
    const signerChainId = await signer.getChainId();
    const address = await signer.getAddress();

    console.log('signerChainId:', signerChainId, 'address:', address);

    if (signerChainId === coreNetwork.chainId) {
      IdentificationContract = UnikaIdentification__factory.connect(contractAddress.UnikaIdentification, signer);
      MediumContract = UnikaMedium__factory.connect(contractAddress.UnikaMedium, signer);
    } else {
      IdentificationContract = UnikaIdentification__factory.connect(contractAddress.UnikaIdentification, coreProvider);
      IdentificationRelayContract = UnikaIdentificationRelay__factory.connect(contractAddress.UnikaIdentificationRelay, signer);
      MediumContract = UnikaMedium__factory.connect(contractAddress.UnikaMedium, coreProvider);
      MediumRelayContract = UnikaMediumRelay__factory.connect(contractAddress.UnikaMediumRelay, signer);
    }

    IdentificationContract.on('InteractiveStageRequestAdded', async (_requestId: BigNumber, clientAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase() && identificationStatus === 'identification-none') {
        console.log('InteractiveStageRequestAdded', _requestId);
        const { position } = await getIdentificationRequestData(address);
        updateIdentificationStatus('identification-queued', { position });
      }
    });

    IdentificationContract.on('InteractiveStageRequestUpdated', (_requestId: BigNumber, clientAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.log('InteractiveStageRequestUpdated', _requestId);
      }
    });

    IdentificationContract.on('InteractiveStageRequestAssigned', async (_requestId: BigNumber, clientAddress: string, validatorAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase() && ['identification-none', 'identification-queued'].includes(identificationStatus)) {
        console.log('InteractiveStageRequestAssigned', _requestId, validatorAddress);
        updateIdentificationStatus('identification-assigned');
      } else if (identificationStatus === 'identification-queued') {
        const { position } = await getIdentificationRequestData(address);
        updateIdentificationStatus('identification-position-updated', { position });
      }
    });

    IdentificationContract.on('InteractiveStageRequestClaimed', async (_requestId: BigNumber, clientAddress: string, validatorAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase() && ['identification-none', 'identification-queued', 'identification-assigned'].includes(identificationStatus)) {
        console.log('InteractiveStageRequestClaimed', _requestId, validatorAddress);
        const { claimTimestamp } = await getIdentificationRequestData(address);
        updateIdentificationStatus('identification-claimed', { claimTimestamp });
      }
    });

    IdentificationContract.on('InteractiveStageRequestResult', (_requestId: BigNumber, clientAddress: string, _: string, result: IdentificationResult, error?: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.log('InteractiveStageRequestResult', result as IdentificationResult, error);
      }
    });

    IdentificationContract.on('IdentificationRequestFinished', (_requestId: BigNumber, clientAddress: string, result: IdentificationResult, timestamp: BigNumber) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.log('IdentificationRequestFinished', result as IdentificationResult, timestamp.toNumber());
        switch (result) {
          case IdentificationResult.PASSED:
            updateIdentificationStatus('identification-success', { identificationTimestamp: timestamp.toNumber() }); break;
          case IdentificationResult.FAILED:
          case IdentificationResult.ERROR:
            updateIdentificationStatus('identification-error'); break;
          default: break;
        }
      }
    });

    IdentificationContract.on('IdentificationRequestTimeout', (_requestId: BigNumber, clientAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.log('IdentificationRequestTimeout');
        updateIdentificationStatus('identification-timeout');
      }
    });

    IdentificationRelayContract?.on('IdentificationRelayRequestReceived', (clientAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.log('IdentificationRelayRequestReceived', clientAddress);
      }
    });

    MediumContract.on('VerificationRequestAdded', async (_requestId: BigNumber, clientAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase() && verificationStatus === 'verification-none') {
        console.log('VerificationRequestAdded', _requestId);
        const { position } = await getVerificationRequestData(address);
        updateVerificationStatus('verification-queued', { position });
      }
    });

    MediumContract.on('VerificationRequestAssigned', async (_requestId: BigNumber, clientAddress: string, validatorAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase() && ['verification-none', 'verification-queued'].includes(verificationStatus)) {
        console.log('VerificationRequestAssigned', _requestId, validatorAddress);
        updateVerificationStatus('verification-assigned');
      } else if (verificationStatus === 'verification-queued') {
        const { position } = await getVerificationRequestData(address);
        updateVerificationStatus('verification-position-updated', { position });
      }
    });

    MediumContract.on('VerificationRequestClaimed', async (_requestId: BigNumber, clientAddress: string, validatorAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase() && ['verification-none', 'verification-queued', 'verification-assigned'].includes(verificationStatus)) {
        console.log('VerificationRequestClaimed', _requestId, validatorAddress);
        const { claimTimestamp } = await getVerificationRequestData(address);
        updateVerificationStatus('verification-claimed', { claimTimestamp });
      }
    });

    MediumContract.on('VerificationRequestResult', (_requestId: BigNumber, clientAddress: string, validatorAddress: string, result: VerificationResult) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.log('VerificationRequestResult', _requestId, validatorAddress, result as VerificationResult);
      }
    });

    MediumContract.on('VerificationRequestFinished', (_requestId: BigNumber, clientAddress: string, result: VerificationResult) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.log('VerificationRequestFinished', _requestId, clientAddress, result as VerificationResult);
        switch (result) {
          case VerificationResult.CONFIRM:
            updateVerificationStatus('verification-confirm'); break;
          case VerificationResult.REJECT:
            updateVerificationStatus('verification-reject'); break;
          case VerificationResult.ERROR:
          case VerificationResult.IDENTIFICATION_NOT_FOUND:
            updateVerificationStatus('verification-error'); break;
          default: break;
        }
      }
    });

    MediumContract.on('VerificationRequestTimeout', (_requestId: BigNumber, clientAddress: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.error('VerificationRequestTimeout', _requestId, clientAddress);
        updateVerificationStatus('verification-timeout');
      }
    });

    MediumRelayContract?.on('MediumRelayRequestReceived', (operation: Operation, serviceAddress: string, serviceRequestId: BigNumber, clientAddress: string, chainid: BigNumber) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.log('MediumRelayRequestReceived', operation, serviceAddress, serviceRequestId.toNumber(), clientAddress, chainid.toNumber());
      }
    });

    MediumRelayContract?.on('MediumRelayRequestFinished', (operation: Operation, serviceAddress: string, serviceRequestId: BigNumber, clientAddress: string, result: VerificationResult, error: string) => {
      if (clientAddress.toLowerCase() === address.toLowerCase()) {
        console.log('MediumRelayRequestFinished', operation, serviceAddress, serviceRequestId.toNumber(), clientAddress, result, error);
        switch (result) {
          case VerificationResult.CONFIRM:
            updateVerificationStatus('verification-confirm'); break;
          case VerificationResult.REJECT:
            updateVerificationStatus('verification-reject'); break;
          case VerificationResult.ERROR:
          case VerificationResult.IDENTIFICATION_NOT_FOUND:
            updateVerificationStatus('verification-error'); break;
          default: break;
        }
      }
    });
  } catch (e) {
    console.error(e);
    throw Error('initialization-error');
  }
};

const deinit = () => {
  IdentificationContract?.removeAllListeners();
  IdentificationRelayContract?.removeAllListeners();
  MediumContract?.removeAllListeners();
  MediumRelayContract?.removeAllListeners();

  IdentificationContract = undefined;
  IdentificationRelayContract = undefined;
  MediumContract = undefined;
  MediumRelayContract = undefined;

  signer = undefined;
  connectionData = undefined;
}

export type IdentificationProps = {
  resourcesDirectory: string;
  customLoader?: HTMLElement;
  cancelButtonImage?: string;
  retryScreenIdealImage?: string;
  cameraPermissionsScreenImage?: string;
  frameColor?: string;
  textColor?: string;
  secondaryColor?: string;
  buttonPressedColor?: string;
  buttonDisabledColor?: string;
  textFontHeader?: string;
  textFontSubtext?: string;
}

const identifyClient = async (_: IdentificationProps) => {
  if (!signer || !IdentificationContract) {
    throw Error('not-initialized');
  } else if (!requestId) {
    throw Error('request-not-found');
  } else if (!connectionData || !connectionData.url || !connectionData.nonce) {
    throw Error('request-not-claimed');
  }

  const {
    resourcesDirectory,
    customLoader,
    cancelButtonImage,
    retryScreenIdealImage,
    cameraPermissionsScreenImage,
    frameColor,
    textColor,
    secondaryColor,
    buttonPressedColor,
    buttonDisabledColor,
    textFontHeader,
    textFontSubtext,
  } = _;

  if (!resourcesDirectory) {
    throw Error('no-resources-directory');
  }

  const nonceSignature = await signer.signMessage(connectionData.nonce);

  const responseSession = await get<SessionTokenResponse>(`${connectionData.url}/session-token`, {
    nonceSignature,
    requestId,
    deviceKeyIdentifier,
    userAgent: createUserAgent(''),
  }, {
    'ngrok-skip-browser-warning': 'true',
  });

  const { sessionToken } = responseSession;

  if (!sessionToken) {
    console.error(responseSession);
    throw Error('initialization-error');
  }

  const initialized = await initialize({
    resourcesDirectory,
    deviceKeyIdentifier,
    publicFaceScanEncryptionKey,
    productionKey,
    customLoader,
    cancelButtonImage,
    retryScreenIdealImage,
    cameraPermissionsScreenImage,
    frameColor,
    textColor,
    secondaryColor,
    buttonPressedColor,
    buttonDisabledColor,
    textFontHeader,
    textFontSubtext,
  });

  if (!initialized) {
    console.warn('initialization-result:', initialized);
    throw Error('initialization-error');
  }

  Distortion.configureLocalization(en);

  Distortion.checkLiveness3D({
    sessionToken,
    onComplete: () => {
      if (identificationError === 'distortion-canceled') {
        updateIdentificationStatus('identification-canceled');
      } else if (identificationError === 'distortion-context-switched') {
        updateIdentificationStatus('identification-context-switched');
      } else if (identificationError) {
        console.error(identificationError);
        updateIdentificationStatus('identification-error');
      } else {
        updateIdentificationStatus('identification-processing');
      }
    },
    onError: (error: string) => identificationError = error,
    onResult: (sessionResult: DistortionSessionResult) =>
      post<CheckLivenessResponse>(`${connectionData!.url}/identification`, {
        faceScan: sessionResult.faceScan,
        auditTrailImage: sessionResult.auditTrail[0],
        lowQualityAuditTrailImage: sessionResult.lowQualityAuditTrail[0],
        nonceSignature,
        requestId,
        deviceKeyIdentifier,
        userAgent: createUserAgent(sessionResult.sessionId as string),
      })
  });
};

export const Unika = {
  init,
  deinit,
  getIdentificationTimestamp,
  getIdentificationRequestData,
  getVerificationRequestData,
  sendIdentificationRequest,
  sendVerificationRequest,
  identifyClient,
};
