import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type DropdownArrowProps = {
  size?: number;
};

export const DropdownArrow: FC<DropdownArrowProps & ClassNameProps> = memo(({
  size = 20,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.09 7.46902L9 10.379L11.91 7.46902C11.9794 7.39958 12.0619 7.3445 12.1526 7.30692C12.2433 7.26934 12.3405 7.25 12.4387 7.25C12.5369 7.25 12.6342 7.26934 12.7249 7.30692C12.8156 7.3445 12.8981 7.39958 12.9675 7.46902C13.0369 7.53845 13.092 7.62089 13.1296 7.71161C13.1672 7.80233 13.1865 7.89957 13.1865 7.99777C13.1865 8.09596 13.1672 8.1932 13.1296 8.28392C13.092 8.37465 13.0369 8.45708 12.9675 8.52652L9.525 11.969C9.45561 12.0385 9.3732 12.0937 9.28247 12.1313C9.19174 12.169 9.09447 12.1883 8.99625 12.1883C8.89802 12.1883 8.80076 12.169 8.71003 12.1313C8.6193 12.0937 8.53688 12.0385 8.4675 11.969L5.025 8.52652C4.95547 8.45713 4.90031 8.37471 4.86267 8.28398C4.82504 8.19325 4.80566 8.09599 4.80566 7.99777C4.80566 7.89954 4.82504 7.80228 4.86267 7.71155C4.90031 7.62082 4.95547 7.5384 5.025 7.46902C5.3175 7.18402 5.7975 7.17652 6.09 7.46902Z" fill="#C7C7C7" />
  </svg>
));
