import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';
import { ThemeColor, ThemeTRBL } from 'theme';
import { VerticalArrangement } from '@spatium/wallet-kit';

export const ModalWrapper = styled.div`
  position: relative;
  pointer-events: all;

  backdrop-filter: blur(8px);
`;

export type BackdropProps = {
  themeColor?: ThemeColor;
  transitionState: TransitionStatus;
  transitionDuration: number;
};

export const Backdrop = styled.div<BackdropProps>`
  ${({ themeColor }) => themeColor && css`
    background: ${themeColor};
  `};

  width: 100%; 
  height: 100%;

  will-change: opacity;
  transition: ${({ transitionDuration }) => transitionDuration}ms;
  opacity: ${({ transitionState }) => (transitionState === 'entered' ? 0.2 : 0)};
`;

export type ContentWrapperProps = {
  themePadding?: ThemeTRBL;
  verticalAlign?: VerticalArrangement;
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  position: absolute;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: ${({ verticalAlign = 'center' }) => ({
    top: 'flex-start',
    bottom: 'flex-end',
    center: 'center',
    'space-around': 'space-around',
    'space-between': 'space-between',
    'space-evenly': 'space-evenly',
  }[verticalAlign])};
  align-items: center;

  box-sizing: border-box;

  ${({ themePadding }) => themePadding && css`
    padding: ${themePadding};
  `};
`;

const translateY = (transitionState: TransitionStatus, verticalAlign: VerticalArrangement) => (
  transitionState !== 'entered' ? ({
    top: '-100%',
    center: '200px',
    bottom: '100%',
    'space-around': '200px',
    'space-between': '200px',
    'space-evenly': '200px',
  }[verticalAlign]) : '0px'
);

export const Content = styled.div<{ stretch?: boolean; transitionState: TransitionStatus; transitionDuration: number; verticalAlign?: VerticalArrangement }>`
  pointer-events: all;
  display: contents;

  & > * {
    transition: ${({ transitionDuration }) => transitionDuration}ms;
    opacity: ${({ transitionState }) => (transitionState === 'entered' ? 1 : 0)};

    transform: translateY(${({ transitionState, verticalAlign = 'center' }) => translateY(transitionState, verticalAlign)});

    will-change: opacity, transform;

    ${({ stretch = false }) => stretch && css`
      width: 100%;
    `}
  }
`;
