import styled, { css } from 'styled-components';
import { Button, theme, ThemeSpace } from 'theme';

export const PrimaryButtonBase = styled(Button) <{ themeWidth: ThemeSpace, noBackground?: boolean }>`
    width: ${({ themeWidth }) => themeWidth};
    height: 59px;
    background: ${({ noBackground }) => noBackground ? 'transparent' : 'linear-gradient(117.46deg, #003DB3 -16.2%, #4123BB 16.75%, #4B00C6 49.51%, #5E36CF 82.27%, #7018C8 117.62%)'};
    border-radius: 8px;
    position: relative;

    ${({ noBackground }) => noBackground ? css`
        &:hover {
            background: ${theme.colors.brown};
        }
    `: css`
      &::before{
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 8px;
          background: linear-gradient(117.46deg, #004EE6 -16.2%, #5635D9 16.75%, #5F00FA 49.51%, #7E5ED9 82.27%, #8A2DE6 117.62%);
          transition: opacity 0.3s linear;
          z-index: -1;
          opacity: 0;
      }

      &:hover:enabled::before {
          opacity: 1;
      }
    `}
`;
