import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type ArbitrumProps = {
  size?: number;
};

export const Arbitrum: FC<ArbitrumProps & ClassNameProps> = memo(({
  size = 20,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1659 9.20581L13.619 6.75484L17.5357 12.8188L17.5376 13.9826L17.5248 5.97453C17.5155 5.77878 17.4109 5.59972 17.2441 5.4941L10.1926 1.46215C10.0277 1.38153 9.81843 1.38246 9.65378 1.4647C9.63153 1.47572 9.61058 1.48779 9.59069 1.50096L9.56607 1.51631L2.72147 5.45902L2.69494 5.47101C2.66079 5.48661 2.62623 5.50646 2.59387 5.52951C2.46406 5.622 2.37791 5.75881 2.35003 5.91227C2.34583 5.93552 2.34274 5.95918 2.34131 5.98312L2.35207 12.5089L6.00027 6.88808C6.45963 6.14278 7.46033 5.90271 8.38935 5.91575L9.47967 5.94435L3.05522 16.1859L3.81253 16.6192L10.3139 5.95476L13.1875 5.94439L6.70294 16.878L9.40525 18.423L9.72812 18.6076C9.86469 18.6628 10.0257 18.6656 10.1634 18.6161L17.3141 14.4969L15.947 15.2844L12.1659 9.20581ZM12.7202 17.1432L9.99091 12.885L11.657 10.0747L15.2415 15.6908L12.7202 17.1432Z" fill="#2D374B" />
    <path d="M9.99097 12.8846L12.7203 17.1428L15.2416 15.6903L11.657 10.0742L9.99097 12.8846Z" fill="#28A0F0" />
    <path d="M17.5376 13.9817L17.5357 12.8179L13.619 6.75391L12.1659 9.20488L15.9469 15.2834L17.314 14.496C17.4481 14.3877 17.5292 14.2288 17.5378 14.0574L17.5376 13.9817Z" fill="#28A0F0" />
    <path d="M1.12463 15.0788L3.05517 16.1846L9.47958 5.94316L8.38926 5.91456C7.46024 5.90152 6.4595 6.14159 6.00019 6.88689L2.35198 12.5077L1.12463 14.3823V15.0788Z" fill="white" />
    <path d="M13.1875 5.94141L10.3139 5.95178L3.8125 16.6162L6.08493 17.9168L6.7029 16.875L13.1875 5.94141Z" fill="white" />
    <path d="M18.7485 5.92727C18.7245 5.32988 18.3991 4.78301 17.8894 4.46462L10.7454 0.38081C10.2412 0.128461 9.61139 0.128137 9.10631 0.380648C9.0466 0.410586 2.15896 4.38129 2.15896 4.38129C2.06363 4.42674 1.97181 4.48087 1.88545 4.54232C1.43046 4.86646 1.15312 5.37019 1.12463 5.92358V14.3811L2.35198 12.5065L2.34126 5.98074C2.34269 5.95688 2.3457 5.93338 2.34998 5.91021C2.37774 5.75667 2.46397 5.6197 2.59382 5.52713C2.62622 5.50408 9.63152 1.47334 9.65377 1.46228C9.81842 1.38008 10.0277 1.37911 10.1926 1.45973L17.2441 5.4916C17.4109 5.59722 17.5155 5.77628 17.5248 5.97203V14.0559C17.5163 14.2273 17.4481 14.3861 17.314 14.4944L15.9469 15.2819L15.2415 15.6883L12.7202 17.1407L10.1634 18.6136C10.0257 18.6631 9.86464 18.6603 9.72807 18.6051L6.70289 16.8755L6.08492 17.9174L8.80354 19.4733C8.89344 19.5241 8.97353 19.5692 9.03926 19.6058C9.14103 19.6625 9.2104 19.7005 9.23489 19.7123C9.42811 19.8056 9.70614 19.8599 9.95667 19.8599C10.1864 19.8599 10.4104 19.818 10.6223 19.7355L18.0489 15.4602C18.4752 15.1319 18.7259 14.6368 18.7485 14.1004V5.92727Z" fill="#96BEDC" />
  </svg>
));
