import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type PolygonProps = {
  size?: number;
};

export const Polygon: FC<PolygonProps & ClassNameProps> = memo(({
  size = 20,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#6F41D8" />
    <path d="M13.1825 7.93313C12.9519 7.79875 12.6525 7.79875 12.3988 7.93313L10.5994 8.96688L9.37754 9.64062L7.57816 10.6737C7.34754 10.8087 7.04816 10.8087 6.79441 10.6737L5.36441 9.865C5.13379 9.73063 4.97254 9.48375 4.97254 9.21375V7.61875C4.97254 7.34937 5.11066 7.1025 5.36441 6.9675L6.77066 6.18125C7.00191 6.04625 7.30191 6.04625 7.55566 6.18125L8.96191 6.9675C9.19316 7.1025 9.35441 7.34937 9.35441 7.61875V8.6525L10.5763 7.95563V6.9225C10.5776 6.78831 10.5417 6.65639 10.4725 6.54141C10.4033 6.42642 10.3036 6.3329 10.1844 6.27125L7.57816 4.78875C7.34754 4.65375 7.04816 4.65375 6.79441 4.78875L4.14191 6.27125C4.02272 6.3329 3.92298 6.42642 3.85379 6.54141C3.7846 6.65639 3.74869 6.78831 3.75004 6.9225V9.91C3.75004 10.18 3.88816 10.4269 4.14191 10.5619L6.79441 12.0444C7.02504 12.1788 7.32504 12.1788 7.57816 12.0444L9.37754 11.0331L10.5994 10.3369L12.3988 9.32625C12.6294 9.19125 12.9288 9.19125 13.1825 9.32625L14.5894 10.1125C14.8207 10.2469 14.9813 10.4938 14.9813 10.7638V12.3588C14.9813 12.6281 14.8438 12.875 14.5894 13.01L13.1832 13.8188C12.9519 13.9538 12.6519 13.9538 12.3988 13.8188L10.9919 13.0325C10.7607 12.8975 10.5994 12.6506 10.5994 12.3812V11.3475L9.37754 12.0444V13.0775C9.37754 13.3469 9.51566 13.5944 9.76941 13.7288L12.4219 15.2112C12.6525 15.3462 12.9519 15.3462 13.2057 15.2112L15.8582 13.7288C16.0888 13.5944 16.25 13.3475 16.25 13.0775V10.09C16.2514 9.95581 16.2155 9.82389 16.1463 9.70891C16.0771 9.59393 15.9774 9.5004 15.8582 9.43875L13.1825 7.93313Z" fill="white" />
  </svg>
));
