import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type FantomProps = {
  size?: number;
};

export const Fantom: FC<FantomProps & ClassNameProps> = memo(({
  size = 20,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#13B5EC" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.75 8.0625L13 6.75V9.375L10.75 8.0625ZM13 13.6875L10 15.4375L7 13.6875V10.625L10 12.375L13 10.625V13.6875ZM7 6.75L9.25 8.0625L7 9.375V6.75ZM10.375 8.6875L12.625 10L10.375 11.3125V8.6875ZM9.625 11.3125L7.375 10L9.625 8.6875V11.3125ZM12.625 6.125L10 7.625L7.375 6.125L10 4.5625L12.625 6.125ZM6.25 5.875V14.0625L10 16.1875L13.75 14.0625V5.875L10 3.75L6.25 5.875Z" fill="white" />
  </svg>
));
