import { Distortion, createCustomization, DistortionCancelButtonLocation } from '@unika/distortion';

import cancel from '../../facetec/images/cancel.svg';
import ideal from '../../facetec/images/ideal.png';
import camera from '../../facetec/images/camera.png';

let initialized = false;

export const initialize = async (_: {
  resourcesDirectory: string,
  deviceKeyIdentifier: string,
  publicFaceScanEncryptionKey: string,
  productionKey?: string,
  customLoader?: HTMLElement,
  cancelButtonImage?: string,
  retryScreenIdealImage?: string,
  cameraPermissionsScreenImage?: string,
  frameColor?: string,
  textColor?: string,
  secondaryColor?: string,
  buttonPressedColor?: string,
  buttonDisabledColor?: string,
  textFontHeader?: string,
  textFontSubtext?: string,
}) => {
  if (initialized) {
    return true;
  }

  const {
    resourcesDirectory,
    deviceKeyIdentifier,
    publicFaceScanEncryptionKey,
    productionKey,
    customLoader,
    cancelButtonImage = cancel,
    retryScreenIdealImage = ideal,
    cameraPermissionsScreenImage = camera,
    frameColor = '#FFFFFF',
    textColor = '#233045',
    secondaryColor = '#04F8CA',
    buttonPressedColor = '#4FFADA',
    buttonDisabledColor = '#B4FDEF',
    textFontHeader = 'san-serif;\nfont-weight: 500',
    textFontSubtext = 'san-serif',
  } = _;

  const customization = createCustomization({
    outerBackgroundColor: 'transparent',
    frameColor: frameColor,
    borderColor: secondaryColor,
    ovalColor: secondaryColor,
    dualSpinnerColor: buttonPressedColor,
    customLoader: customLoader,
    textColor: textColor,
    textFontHeader: textFontHeader,
    textFontSubtext: textFontSubtext,
    buttonAndFeedbackBarColor: secondaryColor,
    buttonAndFeedbackBarTextColor: textColor,
    buttonColorHighlight: buttonPressedColor,
    buttonColorDisabled: buttonDisabledColor,
    cancelButtonLocation: DistortionCancelButtonLocation.TopRight,

    cancelButtonImage: cancelButtonImage,
    retryScreenIdealImage: retryScreenIdealImage,
    cameraPermissionsScreenImage: cameraPermissionsScreenImage,
  });

  initialized = await Distortion.initialize({
    resourcesDirectory,
    deviceKeyIdentifier,
    publicFaceScanEncryptionKey,
    productionKey,
    customization,
  });

  return initialized;
}
