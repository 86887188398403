import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type CheckMarkProps = {
  size?: number;
};

export const CheckMark: FC<CheckMarkProps & ClassNameProps> = memo(({
  size = 32,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="21" fill="black" />
    <path d="M28.2666 44.2654L47.0666 25.4654L43.3333 21.732L28.2666 36.7987L20.6666 29.1987L16.9333 32.932L28.2666 44.2654ZM31.9999 58.6654C28.311 58.6654 24.8444 57.9649 21.5999 56.564C18.3555 55.1649 15.5333 53.2654 13.1333 50.8654C10.7333 48.4654 8.8337 45.6431 7.43459 42.3987C6.0337 39.1543 5.33325 35.6876 5.33325 31.9987C5.33325 28.3098 6.0337 24.8431 7.43459 21.5987C8.8337 18.3543 10.7333 15.532 13.1333 13.132C15.5333 10.732 18.3555 8.83159 21.5999 7.4307C24.8444 6.03159 28.311 5.33203 31.9999 5.33203C35.6888 5.33203 39.1555 6.03159 42.3999 7.4307C45.6444 8.83159 48.4666 10.732 50.8666 13.132C53.2666 15.532 55.1661 18.3543 56.5653 21.5987C57.9661 24.8431 58.6666 28.3098 58.6666 31.9987C58.6666 35.6876 57.9661 39.1543 56.5653 42.3987C55.1661 45.6431 53.2666 48.4654 50.8666 50.8654C48.4666 53.2654 45.6444 55.1649 42.3999 56.564C39.1555 57.9649 35.6888 58.6654 31.9999 58.6654Z" fill="#00C242" />
  </svg>
));
