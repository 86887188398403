import { action } from '@spatium/wallet-kit';
import { Mode } from 'theme';

export type Locale = 'en-us' | 'ru';

export const setLocaleAction = action<{
  locale: Locale;
}>()('local/set-locale');

export const setThemeAction = action<{
  theme: Mode;
}>()('local/set-theme');

export type LocalAction
  = ReturnType<typeof setLocaleAction>
  | ReturnType<typeof setThemeAction>
  ;
