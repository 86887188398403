import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

import { ButtonLoaderBase } from './button-loader.styles';

export type ButtonLoaderProps = {
  size?:  number;
};

export const ButtonLoader: FC<ButtonLoaderProps & ClassNameProps> = memo(({ className, style, size }) => (
  <ButtonLoaderBase className={className} style={style} size={size} />
));
