import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type UnikaLogoProps = {
  size?: number;
};

export const UnikaLogo: FC<UnikaLogoProps & ClassNameProps> = memo(({
  size = 37,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size / 1.54166667} viewBox="0 0 37 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.6" d="M24.5 0C21.3772 0.0748655 18.4027 1.33382 16.1914 3.51668C15.0584 4.62686 14.1594 5.94851 13.5466 7.40501C12.9337 8.86151 12.6192 10.4239 12.6212 12.0016V24C12.6621 24 12.3756 24 12.3756 24C9.20361 23.9348 6.18373 22.6424 3.96366 20.3999C1.74359 18.1575 0.500139 15.1436 0.5 12.0047L0.5 0.731992C0.5 0.537856 0.57795 0.351671 0.716702 0.214396C0.855453 0.0771204 1.04364 0 1.23987 0L24.5 0Z" fill="url(#paint0_linear_528_4030)" />
    <path opacity="0.6" d="M12.5 24C15.6228 23.9251 18.5973 22.6662 20.8086 20.4833C21.9416 19.3731 22.8406 18.0515 23.4534 16.595C24.0663 15.1385 24.3808 13.5761 24.3788 11.9984L24.3788 8.47678e-07C24.3379 8.441e-07 24.6244 8.69147e-07 24.6244 8.69147e-07C27.7964 0.0651924 30.8163 1.3576 33.0363 3.60005C35.2564 5.8425 36.4999 8.85639 36.5 11.9953L36.5 23.268C36.5 23.4621 36.4221 23.6483 36.2833 23.7856C36.1445 23.9229 35.9564 24 35.7601 24L12.5 24Z" fill="url(#paint1_linear_528_4030)" />
    <g filter="url(#filter0_d_528_4030)">
      <path d="M24.5 0V12.0047C24.4909 15.1827 23.2239 18.2281 20.9755 20.4756C18.7271 22.7231 15.6801 23.9901 12.5 24V12.0016C12.5074 8.82244 13.7738 5.7755 16.0223 3.52664C18.2709 1.27778 21.3188 0.00986959 24.5 0V0Z" fill="url(#paint2_linear_528_4030)" />
    </g>
    <defs>
      <filter id="filter0_d_528_4030" x="8.5" y="0" width="20" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.0352941 0 0 0 0 0.0352941 0 0 0 0 0.176471 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_528_4030" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_528_4030" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_528_4030" x1="25.3372" y1="-26.6667" x2="-11.3134" y2="-19.6651" gradientUnits="userSpaceOnUse">
        <stop stopColor="#05D2FF" />
        <stop offset="0.412877" stopColor="#2BF5FF" />
        <stop offset="0.819136" stopColor="#04F8CA" />
      </linearGradient>
      <linearGradient id="paint1_linear_528_4030" x1="11.6628" y1="50.6667" x2="48.3134" y2="43.6651" gradientUnits="userSpaceOnUse">
        <stop stopColor="#05D2FF" />
        <stop offset="0.412877" stopColor="#2BF5FF" />
        <stop offset="0.819136" stopColor="#04F8CA" />
      </linearGradient>
      <linearGradient id="paint2_linear_528_4030" x1="24.9186" y1="-26.6667" x2="6.09624" y2="-24.8688" gradientUnits="userSpaceOnUse">
        <stop stopColor="#05D2FF" />
        <stop offset="0.412877" stopColor="#2BF5FF" />
        <stop offset="0.819136" stopColor="#04F8CA" />
      </linearGradient>
    </defs>
  </svg>
));
