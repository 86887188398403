import { Dispatch } from 'redux';

// eslint-disable-next-line no-restricted-imports
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector, useStore as useReduxStore } from 'react-redux';

import type { ApplicationState } from './reducers';
import type { ApplicationAction } from './actions';

export type { ApplicationState, ApplicationAction };

export { applicationReducer } from './reducers';

export const useSelector = <TSelected>(
  selector: (state: ApplicationState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
) => useReduxSelector(selector, equalityFn);

export const useStore = () => useReduxStore<ApplicationState, ApplicationAction>();

export const useDispatch = () => useReduxDispatch<Dispatch<ApplicationAction>>();
