import { ReactElement } from 'react';
import {
  Chain,
  gnosisChiado,
  goerli,
  optimismGoerli,
  arbitrumGoerli,
  polygonMumbai,
  // zkSyncTestnet,
  avalancheFuji,
  bscTestnet,
  fantomTestnet,
  auroraTestnet,
  celoAlfajores,

  // gnosis,
  // mainnet,
  // optimism,
  // arbitrum,
  // polygon,
  // avalanche,
  // bsc,
  // fantom,
  // aurora,
  // zkSync,
  // celo,
} from 'wagmi/chains';

import { Ethereum } from 'assets/ethereum';
import { Gnosis } from 'assets/gnosis';
import { Optimism } from 'assets/optimism';
import { Arbitrum } from 'assets/arbitrum';
import { Polygon } from 'assets/polygon';
// import { ZKsync } from 'assets/zksync';
import { Avalanche } from 'assets/avalanche';
import { Binance } from 'assets/binance';
import { Fantom } from 'assets/fantom';
import { Aurora } from 'assets/aurora';
import { Celo } from 'assets/celo';
import { Fuse } from 'assets/fuse';

export type ChainObject = Chain & { icon: ReactElement };

export const chains: Array<ChainObject> = [
  { ...gnosisChiado, icon: <Gnosis /> },
  { ...goerli, name: 'Ethereum Goerli', icon: <Ethereum /> },
  { ...optimismGoerli, icon: <Optimism /> },
  { ...arbitrumGoerli, icon: <Arbitrum /> },
  { ...polygonMumbai, icon: <Polygon /> },
  // { ...zkSyncTestnet, icon: <ZKsync /> },
  { ...avalancheFuji, icon: <Avalanche /> },
  { ...bscTestnet, name: 'BNB Testnet', icon: <Binance /> },
  { ...fantomTestnet, icon: <Fantom /> },
  { ...auroraTestnet, icon: <Aurora /> },
  { ...celoAlfajores, name: 'Celo Alfajores', icon: <Celo /> },
  {
    id: 123,
    name: 'Fuse Sparknet',
    network: 'fuse-sparknet',
    nativeCurrency: {
      decimals: 18,
      name: 'Fuse',
      symbol: 'SPARK',
    },
    rpcUrls: {
      public: { http: ['https://rpc.fusespark.io'] },
      default: { http: ['https://rpc.fusespark.io'] },
    },
    blockExplorers: {
      etherscan: { name: 'Fuse Explorer', url: 'https://docs.fuse.io/general/fuse-network-blockchain/fuse-testnet' },
      default: { name: 'Fuse Explorer', url: 'https://docs.fuse.io/general/fuse-network-blockchain/fuse-testnet' },
    },
    icon: <Fuse />
  },

  // { ...gnosis, icon: <Gnosis /> },
  // { ...mainnet, icon: <Ethereum /> },
  // { ...optimism, icon: <Optimism /> },
  // { ...arbitrum, icon: <Arbitrum /> },
  // { ...polygon, icon: <Polygon /> },
  // { ...zkSync, icon: <ZKsync /> },
  // { ...avalanche, icon: <Avalanche /> },
  // { ...bsc, icon: <Binance /> },
  // { ...fantom, icon: <Fantom /> },
  // { ...aurora, icon: <Aurora /> },
  // { ...celo, icon: <Celo /> }
];
