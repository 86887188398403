import { FC, useCallback, ReactElement, cloneElement, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useNavigationLocation } from '@spatium/wallet-kit';

import { useRealtimeRef } from 'lib/utils';

import { Animation, transitionDelay } from 'theme';

import { TransitionWrapper } from './app.styles';

import { mainRoute } from 'screens/main/main.props';
import { Main } from 'screens/main';

const Routes: FC<{}> = ({ children }) => {
  const location = useNavigationLocation<Animation, {}>();

  const animationRef = useRealtimeRef(location.state?.animation);

  const childFactoryCreator = useCallback((child: ReactElement<CSSTransitionProps>) => (
    cloneElement(child, { classNames: animationRef.current })
  ), [animationRef]);

  const { params } = useRouteMatch<{ key: string }>('/:key') ?? { params: { key: undefined } };

  const nodeRef = useRef<HTMLElement>();

  return (
    <TransitionWrapper>
      <TransitionGroup childFactory={childFactoryCreator as any}>
        <CSSTransition key={params.key} timeout={transitionDelay} nodeRef={nodeRef}>
          <Switch location={location}>
            {children}
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </TransitionWrapper>
  );
};

export const AppRouting = () => (
  <Routes>
    <Route exact path="/"><Redirect to={mainRoute} /></Route>
    <Route path={mainRoute} component={Main} />
  </Routes>
);
