import styled from 'styled-components';
import {
  backgroundFactory,
  buttonFactory,
  cardFactory,
  columnFactory,
  modalFactory,
  rowFactory,
  scrollableColumnFactory,
  scrollableRowFactory,
  spacingFactory,
  textFactory,
  themeFactory,
  viewFactory,
  inputFactory,
  ThemeScheme,
  ThemeColor as KitThemeColor,
  ThemeFont as KitThemeFont,
  ThemeShadow as KitThemeShadow,
  ThemeTRBL as KitThemeTRBL,
  ThemeSpace as KitThemeSpace,
  headerRowFactory,
  ColorModifierPropsScheme,
  FontModifierPropsScheme,
  RadiusModifierPropsScheme,
  SizeModifierPropsScheme,
  modifiersModifierFactory,
  sizeModifierFactory,
  radiusModifierFactory,
  colorModifierFactory,
  fontModifierFactory,
} from '@spatium/wallet-kit';
import { TextPropsScheme } from '@spatium/wallet-kit/dist/components/text/text.styles';

export type Theme = ThemeScheme<
  'light' | 'dark', // color mode
  'small' | 'medium' | 'large', // size categories
  'background' | 'foreground' | 'primary' | 'secondary' | 'success' | 'red' | 'tomato' | 'brown' | 'white' | 'gray' | 'grayLight' | 'black' | 'darkWhite' | 'grayWhite' | 'grayDark' | 'orange' | 'lightPurple' | 'purple' | 'whiteLight', // color pallete
  'i1' | 'i2' | 'i3' | 'i4' | 'i5' | 'i6' | 'i7' | 'm1', // fonts
  'unit' | 'small' | 'medium' | 'large' | 'side', // space categories
  'small' | 'medium' | 'large' // shaddow categories
>;

export const theme = themeFactory<Theme>();

export type Mode = Theme['mode'];
export type Size = Theme['size'];
export type ColorKey = keyof Theme['colors'];
export type FontKey = keyof Theme['fonts'];
export type SpaceKey = keyof Theme['spaces'];
export type ShadowKey = keyof Theme['shadows'];

export type ThemeColor = KitThemeColor<Theme>;
export type ThemeFont = KitThemeFont<Theme>;
export type ThemeShadow = KitThemeShadow<Theme>;
export type ThemeSpace = KitThemeSpace<Theme>;
export type ThemeTRBL = KitThemeTRBL<Theme>;

export type SizeModifierProps = SizeModifierPropsScheme<Theme>;
export type RadiusModifierProps = RadiusModifierPropsScheme<Theme>;
export type ColorModifierProps = ColorModifierPropsScheme<Theme>;
export type FontModifierProps = FontModifierPropsScheme<Theme>;
export type TextProps = TextPropsScheme<Theme>;

export const modifiersModifier = modifiersModifierFactory(theme);
export const sizeModifier = sizeModifierFactory(theme);
export const radiusModifier = radiusModifierFactory(theme);
export const colorModifier = colorModifierFactory(theme);
export const fontModifier = fontModifierFactory(theme);

export const Background = backgroundFactory<Theme>(theme);
export const Row = rowFactory<Theme>(theme);
export const Column = columnFactory<Theme>(theme);
export const Card = cardFactory<Theme>(theme);
export const Scrollable = scrollableColumnFactory<Theme>(theme);
export const ScrollableRow = scrollableRowFactory<Theme>(theme);
export const Text = textFactory<Theme>(theme);
export const Spacing = spacingFactory<Theme>(theme);
export const HeaderRow = headerRowFactory<Theme>(theme);
export const Modal = modalFactory<Theme>(theme);
export const Input = inputFactory<Theme>(theme);

export const transitionDelay = 500;
export const transitionDuration = 400;

export type Animation = 'fade' | 'slide-left' | 'slide-right' | 'move-up' | 'move-down' | 'instant';

export const fade: Animation = 'fade';
export const slideLeft: Animation = 'slide-left';
export const slideRight: Animation = 'slide-right';
export const moveUp: Animation = 'move-up';
export const moveDown: Animation = 'move-down';
export const instant: Animation = 'instant';

export const Button = styled(buttonFactory<Theme>(theme))`
  cursor: pointer;
  transition: all 0.3s linear;
  will-change: opacity;  

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const View = styled(viewFactory<Theme>(theme))`
  /** Move up navigation animation */

  &.${moveUp}-enter {
    position: fixed;
    z-index: 100;
    transform: translateY(100%);
  }

  &.${moveUp}-enter.${moveUp}-enter-active {
    position: fixed;
    z-index: 100;
    transform: translateY(0);
    transition: all ${transitionDuration}ms ease-in-out;
    transition-delay: ${transitionDelay};
  }

  &.${moveUp}-exit {
    position: fixed;
    z-index: 99;
  }

  &.${moveUp}-exit.${moveUp}-exit-active {
    position: fixed;
    z-index: 99;
  }

  /** Reverse move up navigation animation */

  &.${moveDown}-enter {
    position: fixed;
    z-index: 99;
  }

  &.${moveDown}-enter.${moveDown}-enter-active {
    position: fixed;
    z-index: 99;
  }

  &.${moveDown}-exit {
    position: fixed;
    z-index: 100;
    transform: translateY(0);
  }

  &.${moveDown}-exit.${moveDown}-exit-active {
    position: fixed;
    z-index: 100;
    transform: translateY(100%);
    transition: all ${transitionDuration}ms ease-in-out;
    transition-delay: ${transitionDelay};
  }

  /** Slide left navigation animation */

  &.${slideLeft}-enter {
    position: fixed;
    z-index: 100;
    transform: translate3d(100%, 0, 0);
    will-change: transform;
  }

  &.${slideLeft}-enter.${slideLeft}-enter-active {
    position: fixed;
    z-index: 100;
    transform: translate3d(0, 0, 0);
    transition: all ${transitionDuration}ms ease-in-out;
    transition-delay: ${transitionDelay};
    will-change: transform;
  }

  &.${slideLeft}-exit {
    position: fixed;
    z-index: 99;
    transform: translate3d(0, 0, 0);
    will-change: transform;
  }

  &.${slideLeft}-exit.${slideLeft}-exit-active {
    position: fixed;
    z-index: 99;
    transform: translate3d(-50%, 0, 0);
    transition: all ${transitionDuration}ms ease-in-out;
    transition-delay: ${transitionDelay};
    will-change: transform;
  }

  /** Reverse slide left navigation animation */

  &.${slideRight}-enter {
    position: fixed;
    z-index: 99;
    transform: translateX(-50%);
    will-change: transform;
  }

  &.${slideRight}-enter.${slideRight}-enter-active {
    position: fixed;
    z-index: 99;
    transform: translateX(0);
    transition: all ${transitionDuration}ms ease-in-out;
    transition-delay: ${transitionDelay};
    will-change: transform;
  }

  &.${slideRight}-exit {
    position: fixed;
    z-index: 100;
    transform: translateX(0);
    will-change: transform;
  }

  &.${slideRight}-exit.${slideRight}-exit-active {
    position: fixed;
    z-index: 100;
    transform: translateX(100%);
    transition: all ${transitionDuration}ms ease-in-out;
    transition-delay: ${transitionDelay};
    will-change: transform;
  }

  /** Fade navigation animation */

  &.${fade}-enter {
    position: fixed;
    z-index: 100;
    opacity: 0;
  }

  &.${fade}-enter.${fade}-enter-active {
    position: fixed;
    z-index: 100;
    transition: opacity 200ms linear;
    opacity: 1;
    will-change: opacity;
  }

  &.${fade}-exit {
    position: fixed;
    z-index: 99;
    opacity: 1;
  }

  &.${fade}-exit.${fade}-exit-active {
    position: fixed;
    z-index: 99;
    opacity: 1;
    transition: opacity 200ms linear;
    will-change: opacity;
  }

  color: ${theme.colors.background.on()};
`;
