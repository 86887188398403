import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

import { Row, theme, ThemeSpace } from 'theme';
import { FadeSwitchWrapper } from 'components/fade-switch-wrapper';
import { ButtonLoader } from 'components/button-loader';

import { PrimaryButtonBase } from './primary-button.styles';

export type PrimaryButtonProps = {
  loading?: boolean;
  noBackground?: boolean;
  themeWidth?: ThemeSpace;
};

export const PrimaryButton: FC<PrimaryButtonProps & ClassNameProps> = memo(({ className, style, children, loading = false, noBackground = false, themeWidth = theme.space(242) }) => (
  <PrimaryButtonBase className={className} style={style} disabled={loading} themeWidth={themeWidth} noBackground={noBackground}>
    <FadeSwitchWrapper switchKey={loading}>
      {loading &&
        <Row verticalAlign='center' themePadding={[theme.space(0), theme.space(8), theme.space(0), theme.space(0)]}>
          <ButtonLoader />
        </Row>}
    </FadeSwitchWrapper>

    {children}
  </PrimaryButtonBase>
));
