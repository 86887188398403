import {
  color,
  font,
  space,
  shadow,
} from '@spatium/wallet-kit';

export const DefaultColors = {
  light: {
    background: [color(['#171717', '#FFFFFF', '#233045'])],
    foreground: [color(['#233045', '#FFFFFF', '#F5F5F5'])],
    primary: [color(['#04F8CA', '#FFFFFF', '#233045'])],

    secondary: [color(['#00B04E', '#FFFFFF', '#FFFFFF'])],
    success: [color(['#00C242', '#FFFFFF', '#FFFFFF'])],

    red: [color(['#FF0366', '#FFFFFF', '#FFFFFF'])],
    tomato: [color(['#D65151', '#FFFFFF', '#FFFFFF'])],
    brown: [color(['#3F3C44', '#FFFFFF', '#FFFFFF'])],
    lightPurple: [color(['#C2B6F2', '#FFFFFF', '#FFFFFF'])],
    purple: [color(['#9E8AEA', '#FFFFFF', '#FFFFFF'])],
    orange: [color(['#FE713A', '#FFFFFF', '#FFFFFF'])],
    black: [color(['#000000', '#FFFFFF', '#FFFFFF'])],
    white: [color(['#FFFFFF', '#FFFFFF', '#1D204F'])],
    whiteLight: [color(['#D9D9D9', '#FFFFFF', '#1D204F'])],
    grayWhite: [color(['#959595', '#FFFFFF', '#1D204F'])],
    darkWhite: [color(['#C7C7C7', '#FFFFFF', '#1D204F'])],
    gray: [color(['#7E7E7E', '#FFFFFF', '#1D204F'])],
    grayLight: [color(['#A2C0CD', '#FFFFFF', '#1D204F'])],
    grayDark: [color(['#262429', '#FFFFFF', '#1D204F'])],
  },
  dark: {
    background: [color(['#171717', '#000000', '#FFFFFF'])],
    foreground: [color(['#FFFFFF', '#000000', '#233045'])],
    primary: [color(['#04F8CA', '#000000', '#233045'])],

    secondary: [color(['#00B04E', '#1D204F', '#FFFFFF'])],
    success: [color(['#00C242', '#1D204F', '#FFFFFF'])],

    red: [color(['#FF0366', '#1D204F', '#FFFFFF'])],
    tomato: [color(['#D65151', '#FFFFFF', '#FFFFFF'])],
    brown: [color(['#3F3C44', '#FFFFFF', '#FFFFFF'])],
    lightPurple: [color(['#C2B6F2', '#FFFFFF', '#FFFFFF'])],
    purple: [color(['#9E8AEA', '#FFFFFF', '#FFFFFF'])],
    orange: [color(['#FE713A', '#FFFFFF', '#FFFFFF'])],
    black: [color(['#000000', '#FFFFFF', '#FFFFFF'])],
    white: [color(['#FCFCFC', '#1D204F', '#1D204F'])],
    whiteLight: [color(['#D9D9D9', '#FFFFFF', '#1D204F'])],
    grayWhite: [color(['#959595', '#FFFFFF', '#1D204F'])],
    darkWhite: [color(['#C7C7C7', '#FFFFFF', '#1D204F'])],
    gray: [color(['#A0A3BD', '#1D204F', '#FFFFFF'])],
    grayLight: [color(['#A2C0CD', '#FFFFFF', '#1D204F'])],
    grayDark: [color(['#262429', '#FFFFFF', '#1D204F'])],
  },
} as const;

export const DefaultFonts = {
  i1: font({
    family: 'Inter, sans-serif',
    size: space(16),
    lineHeight: space(19),
    weight: 700,
    style: 'normal',
  }),
  i2: font({
    family: 'Inter, sans-serif',
    size: space(14),
    lineHeight: space(17),
    weight: 700,
    style: 'normal',
  }),
  i3: font({
    family: 'Inter, sans-serif',
    size: space(54),
    lineHeight: space(65),
    weight: 600,
    style: 'normal',
  }),
  i4: font({
    family: 'Inter, sans-serif',
    size: space(32),
    lineHeight: space(39),
    weight: 600,
    style: 'normal',
  }),
  i5: font({
    family: 'Inter, sans-serif',
    size: space(16),
    lineHeight: space(19),
    weight: 400,
    style: 'normal',
  }),
  i6: font({
    family: 'Inter, sans-serif',
    size: space(12),
    lineHeight: space(15),
    weight: 400,
    style: 'normal',
  }),
  i7: font({
    family: 'Inter, sans-serif',
    size: space(14),
    lineHeight: space(21),
    weight: 400,
    style: 'normal',
  }),

  m1: font({
    family: 'Inconsolata, sans-serif',
    size: space(16),
    lineHeight: space(18),
    weight: 400,
    style: 'normal',
  }),
} as const;

export const DefaultSpaces = {
  small: {
    unit: space(3),
    small: space(6),
    medium: space(12),
    large: space(18),
    side: space(16),
  },
  medium: {
    unit: space(5),
    small: space(10),
    medium: space(20),
    large: space(30),
    side: space(16),
  },
  large: {
    unit: space(7),
    small: space(15),
    medium: space(30),
    large: space(45),
    side: space(16),
  },
} as const;

export const DefaultShadows = {
  small: shadow({
    color: color(['black']),
    params: [[0, 3, 5, -1, 0.2], [0, 6, 10, 0, 0.14], [0, 1, 18, 0, 0.12]],
  }),
  medium: shadow({
    color: color(['black']),
    params: [[0, 7, 8, -4, 0.2], [0, 12, 17, 2, 0.14], [0, 5, 22, 4, 0.12]],
  }),
  large: shadow({
    color: color(['black']),
    params: [[0, 9, 11, -5, 0.2], [0, 18, 28, 2, 0.14], [0, 7, 34, 6, 0.12]],
  }),
} as const;

export const DefaultScales = {
  small: 1,
  medium: 1,
  large: 1,
} as const;

export const DefaultSizes = {
  small: 0,
  medium: 1,
  large: 2,
} as const;
