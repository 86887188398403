import styled from 'styled-components';

export const SnackSwitchWrapperBase = styled.div<{ transitionDuration?: number }>`
  transition: ${({ transitionDuration = 300 }) => transitionDuration}ms;

  &.snack-enter {
    opacity: 0;
    transform: translateX(-100%);
  }

  &.snack-enter.snack-enter-active {
    opacity: 1;
    transform: translateX(0);
  }

  &.snack-exit {
    opacity: 1;
    transform: translateX(0);
  }

  &.snack-exit.snack-exit-active {
    opacity: 0;
    transform: translateX(-100%);
  }
`;
