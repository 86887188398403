import { CombinedState, combineReducers, Reducer } from 'redux';
import { ApplicationAction } from './actions';
import { localReducer, LocalState } from './local';

export type ApplicationState = {
  local: LocalState;
};

export const applicationReducer = combineReducers<ApplicationState>({
  local: localReducer,
}) as Reducer<CombinedState<ApplicationState>, ApplicationAction>;
