import { Reducer } from 'redux';
import { Mode } from 'theme';
import { LocalAction, Locale } from './actions';

export type LocalState = {
  locale: Locale;
  mode: Mode;
};

export const localReducer: Reducer<LocalState, LocalAction> = (state, action) => {
  const initial: LocalState = {
    locale: ['en-us', 'ru'].includes(navigator.language) ? navigator.language as Locale : 'en-us',
    mode: 'light',
  };

  if (!state) {
    return initial;
  }

  switch (action.type) {
    case 'local/set-locale':
      return {
        ...state,
        locale: action.locale,
      };
    case 'local/set-theme':
      return {
        ...state,
        theme: action.theme,
      };
    default: return state;
  }
};
