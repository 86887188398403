import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './app';
import reportWebVitals from './reportWebVitals';

(window as any).Buffer = (window as any).Buffer || require('buffer').Buffer;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
