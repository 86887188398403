import { FC, memo, useRef } from 'react';
import { ClassNameProps, Pressable } from '@spatium/wallet-kit';

import { theme, Text, Spacing } from 'theme';
import { StandardSnack, standardSnacks } from 'lib/snack';

import { SnackWrapper, Close } from './snack.styles';

export type SnackProps = {
  keyValue?: string;
  values?: Record<string, any>;
  onClose?: () => void;
};

export const Snack: FC<SnackProps & ClassNameProps> = memo(({ className, style, keyValue, values, onClose }) => {
  const keyValueSnack = useRef<StandardSnack>(keyValue as StandardSnack);

  const { message, variant } = standardSnacks[keyValueSnack.current](values);

  return (
    <SnackWrapper
      variant={variant}
      className={className}
      style={style}
      horizontalAlign="space-between"
      verticalAlign="center"
    >
      <Text
        themeFont={theme.fonts.i2.override({ weight: 500 })}
        themeTextColor={variant === 'success' ? theme.colors.success : theme.colors.tomato}>
        {message}
      </Text>
      <Spacing themeSpace={theme.space(10)} />

      <Pressable onClick={onClose}>
        <Close />
      </Pressable>
    </SnackWrapper>
  );
});
