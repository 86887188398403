export const ru = {
  'app.main.connect-wallet': 'Connect wallet',
  'app.main.title': 'Demo airdrop',
  'app.main.info': 'To claim your tokens you should be anonymously verified',
  'app.main.tooltip': 'You will be verified by our reliable partner - the UNIKA Network. Anonymity (programmable privacy) will be implemented in the future versions of UNIKA. For now, your information is private according to UNIKA {privacy} & {terms}.',
  'app.main.privacy': 'Privacy Policy',
  'app.main.terms': 'Terms of Use',
  'app.main.claim-info': 'After clicking button we will ask you{br}to connect your MetaMask Wallet to Demo AirDrop.{br}After we will automatically check your verification with UNIKA.',
  'app.main.connecting-info': 'Wallet is сonnecting',
  'app.main.signing-tx': 'Signing transaction',
  'app.main.approve-sharing': 'Approving data sharing',
  'app.main.sending-service': 'Communicating with service smart contract',
  'app.main.sending-unika': 'Communicating with UNIKA smart contract',
  'app.main.connected-info': 'After clicking button we will ask you to sign transactions in your wallet.{br}This is free.{br}When transactions are signed we receive your request and check eligibility.',
  'app.main.verified-unika': 'Anonymously verified{br}with UNIKA',
  'app.main.outdated-unika': 'Your UNIKA verification{br}is outdated',
  'app.main.not-verified-unika': 'You are not verified with{br}UNIKA',
  'app.main.not-confirmed': 'Eligibility{br}not confirmed',
  'app.main.denied': 'Denied',
  'app.main.denied-reason': 'We detected that you participate in this Airdrop.{br}Your addresses {account} is already whitelisted',
  'app.main.redirected': 'You will be redirected to UNIKA to get anonymously verified',
  'app.main.wait': 'Please wait',
  'app.main.received-request': 'We received your request.{br}Now we are checking whether you are eligible to claim tokens and be whitelisted.',
  'app.main.eligibility': 'Checking your{br}eligibility',
  'app.main.success': 'Success',
  'app.main.whitelist': 'You wallet address {account}{br}is in the whitelist for this Demo AirDrop',
  'app.main.check-whitelist': 'Check whitelist',
  'app.main.eligibility-confirmed': 'Eligibility{br}confirmed',
  'app.main.participate': 'We detected that you already participate in this Airdrop.{br}One of your addresses is already whitelisted',
  'app.main.support': 'Support',
  'app.main.modal.title': 'Demo AirDrop White List',

  'app.main.wallets-modal.title': 'SELECT CHAIN',

  'app.main.connect-wallets-modal.mobile-title': 'CONNECT YOUR',
  'app.main.connect-wallets-modal.title': 'CONNECT YOUR WALLET',
  'app.main.connect-wallets-modal.meta': 'MetaMask',
  'app.main.connect-wallets-modal.wallet-connect': 'wallet Connect',
  'app.main.connect-wallets-modal.coinbase': 'coinbase wallet',
  'app.main.connect-wallets-modal.bottom-text': 'By connecting a wallet, you agree with UNIKA {privacy} & {terms}',

  'app.main.cookie-banner': 'We use cookies on our website to ensure that we provide the best user experience for you. For more details please read our {privacy} & {terms}',
  'app.main.cookie-agree': 'I AGREE',
  
  'app.main.possibility-window.button-title': 'Try More',
  'app.main.possibility-window.title': 'Try all UNIKA possibilities',
  'app.main.possibility-window.desc': 'If you have not tried all UNIKA possibilities yet, we propose you to do it right now. Make sure that you can claim tokens only once, and this Demo Airdrop is fully protected from identity misuse risks thanks to UNIKA.',
  'app.main.possibility-window.first-step-title': 'multi-accounting protection',
  'app.main.possibility-window.first-step-text': 'Disconnect you wallet and claiming tokens with different address',
  'app.main.possibility-window.second-step-title': 'Account Transfer made haredr',

  'app.main.suppory-window.top': 'Dear friend, we appreciate you tried demo of UNIKA possibilities. Hopefully, you find it valuable.',
  'app.main.suppory-window.middle': 'This is very important for us to get your feedback or discuss any questions you have. With your help we’ll be able to keep Web3 truly anonymous and fraud free.',
  'app.main.suppory-window.bottom': 'Moreover, currently you can benefit from UNIKA without any payments.',
  'app.main.suppory-window.contact': 'Drop me a line at {email} or message us in {telegram}',
  'app.main.suppory-window.telemram': 'Telegram',
  'app.main.suppory-window.name': '<i>Max,</i>',
  'app.main.suppory-window.position': '<i>Co-Founder, CEO at UNIKA</i>',

  'app.main.welcome-modal.warning': 'Warning! This is not a real airdrop. The page is to illustrate UNIKA functionality based on the airdrops use-case.',
  'app.main.welcome-modal.title': 'Welcome to unika demo',
  'app.main.welcome-modal.possibility': 'Feel UNIKA possibilities on the example of Airdrop.',
  'app.main.welcome-modal.claim': 'Make sure that you can claim tokens only once, and this Demo Airdrop is fully protected from identity misuse risks thanks to UNIKA.',
  'app.main.welcome-modal.first-step-title': 'try claiming the airdrop',
  'app.main.welcome-modal.first-step-text': 'Firstly, claim airdrop with one of your addresses',
  'app.main.welcome-modal.second-step-title': 'test multi-accounting protection',
  'app.main.welcome-modal.second-step-text': 'After you claimed airdrop, disconnect your wallet and try claiming tokens with different address',
  'app.main.welcome-modal.third-step-title': 'Account Transfer made harder',
  'app.main.welcome-modal.third-step-text': 'Simulate account transfer. Ask your friend to get verified with {unika} and after {time} use your friend’s wallet account to claim tokens.',
  'app.main.welcome-modal.button': 'Got it',
  'app.main.welcome-modal.time': '24h',
  'app.main.welcome-modal.tooltip': 'Verification validity time can be customized depending on your specific needs',
  'app.main.welcome-modal.unika': 'UNIKA',

  'app.main.info-modal.text': 'You will be anonymously verified (no one can identify you) by our reliable partner - the UNIKA Network.',
  'app.main.info-modal.got': 'Got it',

  'app.main.confirm-modal.title': 'Confirm answering',
  'app.main.confirm-modal-desktop.info': 'Please, confirm that you allow UNIKA to provide yes/no answer to the{br}questions asked by',
  'app.main.confirm-modal-mobile.info': 'Please, confirm that you allow UNIKA to provide yes/no answer to the questions asked by',
  'app.main.confirm-modal.question': 'Does any address of this user already participate in this Airdrop?',
  'app.main.confirm-modal.airdrop': 'Demo Airdrop',
  'app.main.confirm-modal.reject': 'Reject',
  'app.main.confirm-modal.confirm': 'Confirm',

  'app.main.metamask-modal.title': 'Connect your wallet',
  'app.main.metamask-modal.info': 'Please, install MetaMask extension and try again.',
  'app.main.metamask-modal.extension': 'Install extension',
  'app.main.metamask-modal.try-again': 'Try again',

  'app.main.error-modal.wrong': 'SOMETHING WENT WRONG',
  'app.main.error-modal.try': 'Please try again.{br}If it happens again contact our support.',
  'app.main.error-modal.try-again': 'Try again',

  'app.main.button.connecting': 'Connecting',
  'app.main.button.signing': 'Signing',
  'app.main.button.waiting': 'Waiting',
  'app.main.button.claim': 'Claim Tokens',

  'snack.wallet-connected': 'Wallet is successfully connected',
  'snack.tx-sent': 'Transaction successfully signed and sent',
  'snack.approved': 'Data sharing request approved and sent',
  'snack.operation-canceled': 'Operation canceled',
  'snack.metamask-not-installed': 'Metamask wallet is not installed',
  'snack.unsupported-chain': 'Network selected in the wallet is not supported. Please, change the network',
  'snack.unknown-error': 'Something went wrong. Please, try again',
};

export default ru;
