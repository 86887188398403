import styled, { css } from 'styled-components';
import { Column, Row, Text, Button, theme, ThemeColor, Scrollable as ScrollableBase } from 'theme';
import { Close as CloseIcon } from 'assets/close';
import { PrimaryButton } from 'components/primary-button';
import { DropdownArrow } from 'assets/dropdown-arrow';

export const Scrollable = styled(ScrollableBase)`
  & > *:first-child {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export const ModalButton = styled(PrimaryButton)``;

export const ModalButtonWrapper = styled.div`
    @media screen and (max-width: 768px) {
        width: 100%;
        background: ${theme.colors.grayDark};

        ${ModalButton} {
            width: 100%;
        }
    }
`;

export const MainWrapper = styled(Column)`
    height: 378px;
`;

export const ModalWrapper = styled.div`
    max-width: 800px;
    height: 80vh;
    padding: 10px;
    overflow: hidden;
    border: 1px solid ${theme.colors.black};
    background: ${theme.colors.grayDark};
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
`;

export const BaseModalWrapper = styled(Column)`
    max-width: 800px;
    padding: 32px;
    position: relative;
    background: ${theme.colors.grayDark};
    border: 1px solid ${theme.colors.black};
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
`;

export const MobileModalWrapper = styled(BaseModalWrapper)`
    max-width: 800px;
    width: auto;
    padding: 32px 16px;
    border-radius: 10px;
    z-index: 200;

    ${ModalButton} {
        width: 100%;
    }
`;

export const WelcomeModalWrapper = styled(BaseModalWrapper)`
    padding: 0 0 32px 0;
    margin: 0 auto;
    
    @media screen and (max-width: 768px) {
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px 10px 0 0;
        border-bottom: none;
        
        ${ModalButton} {
            width: 100%;
        }
    }
`;

export const WelcomeModalContentWrapper = styled(Column)`
   @media screen and (max-width: 768px) {
        overflow-y: scroll;
        max-height: 65vh;
    }
`;

export const StepList = styled(Row)`
   @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StepWrapper = styled(Column)`
   width: 32%;

   ${Text} {
    flex-shrink: 1;
   }

   @media screen and (max-width: 768px) {
        width: 100%;

        &:not(:last-child){
            margin-bottom: 16px;
        }
    }
`;

export const Step = styled(Row)`
    width: 33px;
    height: 33px;
    background: ${theme.colors.darkWhite};
    border-radius: 50%;
`;

export const TimeToolTip = styled(Text)`
    width: 288px;
`;

export const Time = styled.span<{ triangle?: boolean }>`
    border-bottom: 1px dashed ${theme.colors.white};
    cursor: pointer;
    transition: color .2s;

    &:hover{
        color: ${theme.colors.purple};
    }
`;

export const TextWithBackground = styled(Text)`
    display: inline-block;
    padding: 10px;
    background: ${theme.colors.background};
    border-radius: 3px;
`;

export const MetamaskModalClose = styled(CloseIcon)`
    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;
`;

export const ErrorModalWrapper = styled(Column)`
    width: 800px;
    background: ${theme.colors.grayDark};
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
`;

export const ModalHeader = styled(Row)`
    padding: 22px;
    margin-left: -10px;
    margin-right: -10px;
    border-bottom: 1px solid ${theme.colors.black};

    @media screen and (max-width: 768px) {
        border-bottom: none;
        margin: 0;
        padding: 32px 8px 0;

        ${Text} {
            margin-right: 10px;
            flex-shrink: 1;
        }
    }
`;

export const Close = styled(CloseIcon)`
    margin-left: auto;
    cursor: pointer;
`;

export const List = styled.div`
    height: 85%;
    padding: 0 22px 22px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: ${theme.colors.whiteLight};
    }

    @media screen and (max-width: 768px) {
        padding: 0 8px 22px;
    }
`;

export const Warning = styled(Column)`
    width: 100%;
    padding: 8px;
    background: ${theme.colors.orange};
    border-radius: 30px 30px 0 0;

    @media screen and (max-width: 768px) {
        border-radius: 10px 10px 0 0;
        position: sticky;
        top: 0;
        z-index: 100;
    }
`;

export const SecondaryButton = styled(Button) <{ small?: boolean }>`
    padding: 10px;
    background: ${theme.colors.purple};
    border-radius: 5px;
    transition: background opacity 0.3s linear;

    ${({ small }) => small && css`
        padding: 7.5px 9px;
    `}

    &:hover:enabled {
        background: ${theme.colors.lightPurple};
    }

    &:active {
        opacity: 0.6;
    }
`;

export const Verified = styled(Row)`
    padding: 6px 10px;
    background: ${theme.colors.black};
    border: 1px solid ${theme.colors.darkWhite};
    border-radius: 5px;

    svg:last-child {
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        padding: 6.5px 12px;
    }
`;

export const VerticalLine = styled.div`
    width: 2px;
    height: 24px;
    background-color: ${theme.colors.whiteLight};

    @media screen and (max-width: 768px) {
        width: 1px;
        height: 17px;
    }
`;

export const StatusDropWrapper = styled(Row)`
   @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const StatusDrop = styled(Column)`
    width: 193px;

    @media screen and (max-width: 768px) {
        width: 50%;
    }
`;

export const ToolTip = styled(Text)`
    position: absolute;
    background: ${theme.colors.grayDark};
    border-radius: 10px;
    padding: 12px 16px;
    top: 35px;
    letter-spacing: 0.02em;
    width: 498px;
    transform: translateX(-50%);
    z-index: 10;
    filter: drop-shadow(0px 7px 25px rgba(35, 48, 69, 0.1));
    
    &::before {
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent; 
        border-bottom:8px solid ${theme.colors.grayDark};
        top: -8px;
        left: calc(50% + 2.5px);
    }

    a {
        color: ${theme.colors.white};
    }
`;

export const IconWrapper = styled.div`
    position: relative;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    transition: all .2s;
    cursor: pointer;

    svg {
        path {
            transition: all .2s;
        }
    }

    &:hover {
        svg {
            path {
                fill: ${theme.colors.purple};
            }
        }
        ${ToolTip} {
            display: block;
        }
    }
`;

export const Info = styled(Text)`
    letter-spacing: 0.05em;
`;

export const SupportWrapper = styled.div<{ window: boolean }>`
    width: auto !important;
    flex-grow: 0 !important;
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: ${({ window }) => window && 10};

    @media screen and (max-width: 768px) {
        right: 16px;
    }
`;

export const SupportLink = styled.a`
    display: block;
    width: 158px !important;
    height: 48px;
    background: ${theme.colors.grayDark};
    text-decoration: none;
    border: 1px solid ${theme.colors.black};
    box-shadow: 0px 74px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    text-decoration: none;
    transition: background 0.3s linear;

    &:hover {
        background: ${theme.colors.brown};
    }

    ${Row} {
        height: 100%;
    }

    @media screen and (max-width: 768px) {
        width: 56px !important;
        height: 56px;
    }
`;

export const PossibilityWrapper = styled.div<{ window: boolean }>`
    width: auto !important;
    flex-grow: 0 !important;
    position: fixed;
    left: 30px;
    bottom: 30px;
    z-index: ${({ window }) => window && 10};

    @media screen and (max-width: 768px) {
        left: 16px;
    }
`;

export const Possibility = styled(Button)`
    width: 122px !important;
    height: 44px;
    padding: 6px 12px;
    background: linear-gradient(117.46deg, #003DB3 -16.2%, #4123BB 16.75%, #4B00C6 49.51%, #5E36CF 82.27%, #7018C8 117.62%);
    border-radius: 60px;
    cursor: pointer;
    position: relative;
    z-index: 1;

    &::before{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 60px;
        background: linear-gradient(117.46deg, #004EE6 -16.2%, #5635D9 16.75%, #5F00FA 49.51%, #7E5ED9 82.27%, #8A2DE6 117.62%);
        transition: opacity 0.3s linear;
        z-index: -1;
        opacity: 0;
    }

    &:hover::before {
        opacity: 1;
    }

    ${Text} {
        flex-shrink: 1 !important;
    }
`;

export const Arm = styled(Text)`
    padding: 8px;
    background: linear-gradient(117.46deg, #003DB3 -16.2%, #4123BB 16.75%, #4B00C6 49.51%, #5E36CF 82.27%, #7018C8 117.62%);
    border-radius: 20px;
`;

export const WindowCloseButtonWrapper = styled(Button) <{ small?: boolean }>`
    width: ${({ small }) => small ? '44px' : '65px'};
    height: ${({ small }) => small ? '44px' : '65px'};
    background: ${theme.colors.white};
    border-radius: 50%;
    cursor: pointer;

    ${Close} {
        margin: 0;
    }
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background: ${theme.colors.black};;
`;

export const PossibilityWindow = styled(Column)`
    width: 366px !important;
    max-height: none !important;
    padding: 12px 16px;
    position: absolute;
    left: 0;
    bottom: calc(100% + 12px);
    background: ${theme.colors.grayDark};
    border: 1px solid ${theme.colors.black};
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.8);
    border-radius: 10px;

    ${Text}, ${Row}, ${Column} {
        flex-shrink: 1;
    }

    ${Step} {
        flex-shrink: 0;
    }

    @media screen and (max-width: 475px) {
        width: 343px !important;
    }
`;

export const Employee = styled(Button)`
    width: 65px;
    height: 65px;
    background: linear-gradient(117.46deg, #003DB3 -16.2%, #4123BB 16.75%, #4B00C6 49.51%, #5E36CF 82.27%, #7018C8 117.62%);
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    position: relative;
    z-index: 1;
    
    &::before{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        background: linear-gradient(117.46deg, #004EE6 -16.2%, #5635D9 16.75%, #5F00FA 49.51%, #7E5ED9 82.27%, #8A2DE6 117.62%);
        transition: opacity 0.3s linear;
        z-index: -1;
        opacity: 0;
    }

    &:hover::before {
        opacity: 1;
    }

    img {
        width: 49px;
        height: 49px;
        border-radius: 50%;
    }
`;

export const Notfication = styled(Row)`
    width: 23px;
    height: 23px;
    position: absolute;
    right: 0;
    top: 0;
    background: ${theme.colors.tomato};
    border-radius: 50%;
`;

export const EmployeeSupportWindow = styled(Column)`
    width: 366px !important;
    max-height: none !important;
    padding: 12px 16px;
    position: absolute;
    right: 0;
    bottom: calc(100% + 12px);
    background: ${theme.colors.grayDark};
    border: 1px solid ${theme.colors.black};
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.8);
    border-radius: 10px;

    img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    @media screen and (max-width: 475px) {
        width: 343px !important;
    }
`;

export const WhiteListModalWrapper = styled(ModalWrapper)`
    @media screen and (max-width: 768px) {
        padding: 8px;
        border-radius: 10px 10px 0 0;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

export const AccountId = styled(Text)`
    @media screen and (max-width: 768px) {
        width: 70%;
        word-break: break-all;
        margin-right: 10px;
    }
`;

export const WelcomeModalBase = styled.div`

    @media screen and (max-width: 768px) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        ${ModalButtonWrapper} {
            position: fixed;
            bottom: 0;
            padding: 0 16px 16px;
            border: 1px solid ${theme.colors.black};
            border-top: none;
        }
    }
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(23,23,23, .5);
`;

export const ConfirmModalWrapper = styled(BaseModalWrapper)`

    @media screen and (max-width: 768px) {
        padding: 32px 16px 50px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 10px 10px 0 0;
    }
`;

export const ConfirmModalButtonWrapper = styled(Row)`

    @media screen and (max-width: 768px) {
        width: 100%;

        ${ModalButton} {
            flex-grow: 1 !important;
            flex-shrink: 1 !important;
        }
    }
`;

export const BottomModalWrapper = styled(Column)`
    background: ${theme.colors.grayDark};
    border: 1px solid ${theme.colors.black};

    @media screen and (max-width: 768px) {
        padding: 32px 16px 50px;
        border-radius: 10px 10px 0 0;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

export const ChainsListModal = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
        display: flex;
        align-items: center;
        padding: 12px;
        cursor: pointer;
    }
`;

export const HeadChainsModal = styled(Row)`
    width: 100%;
`;

export const ConnectWalletModalWrapper = styled(Column)`
    max-width: 800px;
    padding: 32px 102px;
    border: 1px solid ${theme.colors.black};
    background: ${theme.colors.grayDark};
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
    border-radius: 30px;

    ${Close} {
        position: absolute;
        top: 20px;
        right: 20px;
    }
`;

export const ConnectWalletWrapper = styled(Row)`
    width: 100%;

    @media screen and (max-width: 768px) {
        flex-direction:  column;
    }
`;

export const ConnectWalletItem = styled(Column)`
    width: 188px;
    height: 128px;
    border: 1px solid ${theme.colors.darkWhite};
    border-radius: 10px;
    transition: background .2s ease-in-out;
    cursor: pointer;

    &:hover {
        background: ${theme.colors.black};
    }

    @media screen and (max-width: 768px) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 61px;

        ${Text} {
            width: auto;
        }
    }
`;

export const BottomModalHeader = styled(Row)`
    width: 100%;

    ${Text} {
        flex-shrink: 1;
    }
`;

export const Arrow = styled(DropdownArrow) <{ down: boolean }>`
    transition: transform .2s ease-in-out;
    transform: rotate(${({ down }) => down ? '-180deg' : '0'});
`;

export const ChainsWrapper = styled.div`
    position: relative;
`;

export const SelectedChain = styled(Row)`
    cursor: pointer;
    padding: 8.5px 16px;
    background: ${theme.colors.black};
    border: 1px solid ${theme.colors.purple};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    transition: all .2s ease-in-out;

    @media screen and (max-width: 768px) {
        padding: 5px 12px;
    }
`;

export const ChainDropdown = styled.ul`
    min-width: 216px;
    list-style: none;
    margin: 0;
    padding: 11px 8px;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;

    background: ${theme.colors.grayDark};
    border: 1px solid ${theme.colors.black};
    box-shadow: 0px 7px 25px rgba(35, 48, 69, 0.1);
    border-radius: 5px;
`;

export const ChainItem = styled.li<{ active: boolean }>`
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;

    ${({ active }) => active && css`
        background: ${theme.colors.background};
        border-radius: 5px;
    `}

    &:hover {
        background: ${theme.colors.background};
        border-radius: 5px;
    }
`;

export const Link = styled.a<{ underlineColor?: ThemeColor }>`
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid ${({ underlineColor = theme.colors.white }) => underlineColor};
    cursor: pointer;
`;
