import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type ModalDottedLineProps = {
  size?: number;
};

export const ModalDottedLine: FC<ModalDottedLineProps & ClassNameProps> = memo(({
  size = '100%',
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={1} viewBox="0 0 736 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0.5" y1="0.5" x2="735.5" y2="0.5" stroke="#C7C7C7" strokeLinecap="round" strokeDasharray="8 8" />
  </svg>
));
