import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type GnosisProps = {
  size?: number;
};

export const Gnosis: FC<GnosisProps & ClassNameProps> = memo(({
  size = 20,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1970_6306)">
      <rect width="20" height="20" rx="10" fill="white" />
      <path d="M5.91697 11.3667C6.50872 11.3667 7.05497 11.1688 7.49503 10.8263L3.88368 7.20312C3.54227 7.63701 3.34501 8.18503 3.34501 8.78637C3.33743 10.2097 4.49063 11.3667 5.91697 11.3667Z" fill="#03795B" />
      <path d="M16.6598 8.77852C16.6598 8.18483 16.4625 7.6368 16.1211 7.19531L12.5098 10.8184C12.9422 11.161 13.4885 11.3589 14.0878 11.3589C15.5066 11.3665 16.6598 10.2095 16.6598 8.77852Z" fill="#03795B" />
      <path d="M18.4807 4.84375L16.8799 6.44983C17.411 7.08917 17.7296 7.89602 17.7296 8.79422C17.7296 10.8113 16.0984 12.4478 14.0879 12.4478C13.2002 12.4478 12.3884 12.1281 11.7512 11.5953L9.99858 13.3535L8.246 11.5953C7.60872 12.1281 6.8045 12.4478 5.90926 12.4478C3.89872 12.4478 2.26754 10.8113 2.26754 8.79422C2.26754 7.90363 2.5862 7.08917 3.11729 6.44983L2.29791 5.62776L1.51644 4.84375C0.606027 6.35086 0.0825195 8.10917 0.0825195 9.99685C0.0825195 15.4924 4.52083 19.9376 9.991 19.9376C15.4611 19.9376 19.8994 15.4848 19.8994 9.99685C19.9146 8.10156 19.3911 6.34325 18.4807 4.84375Z" fill="#03795B" />
      <path d="M17.1681 3.12957C15.37 1.23429 12.8208 0.046875 9.99847 0.046875C7.17613 0.046875 4.63454 1.23429 2.82884 3.12957C2.58608 3.38839 2.35091 3.66242 2.13086 3.94403L9.99089 11.8297L17.8509 3.93642C17.6536 3.66238 17.4184 3.38078 17.1681 3.12957ZM9.99847 1.34085C12.32 1.34085 14.4747 2.23902 16.0907 3.87554L9.99847 9.98768L3.90619 3.87554C5.52978 2.23902 7.67689 1.34085 9.99847 1.34085Z" fill="#03795B" />
    </g>
    <defs>
      <clipPath id="clip0_1970_6306">
        <rect width="20" height="20" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
));
