import { FC, memo } from 'react';
import { ClassNameProps, layout } from '@spatium/wallet-kit';

import { SwitchWrapper } from 'components/switch-wrapper';

import { FadeSwitchWrapperBase } from './fade-switch-wrapper.styles';

export type FadeSwitchWrapperProps = {
  switchKey: string | number | boolean | null;
  transitionDuration?: number;
};

export const FadeSwitchWrapper: FC<FadeSwitchWrapperProps & ClassNameProps> = memo(({ className = "fade", style, switchKey, children, transitionDuration = 300 }) => (
  <SwitchWrapper switchKey={switchKey} transitionDuration={transitionDuration} classNames={className}>
    <FadeSwitchWrapperBase {...layout.ignore(className)} style={style} transitionDuration={transitionDuration}>
      {children}
    </FadeSwitchWrapperBase>
  </SwitchWrapper>
));
