import styled from 'styled-components';
import { Row, theme, Text } from 'theme';
import { Close as CloseAsset } from 'assets/close';

export const SnackWrapper = styled(Row) <{ variant: 'failure' | 'success' }>`
  pointer-events: all;
  width: 470px;
  min-height: 60px;
  padding: 0 40px 0 20px;
  background: ${theme.colors.black};
  box-shadow: 0px 4px 34px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-left: 9px solid ${({ variant }) => variant === 'success' ? theme.colors.success : theme.colors.tomato};

  ${Text} {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Close = styled(CloseAsset)`
  pointer-events: all;
  cursor: pointer;
  width: 14.58px;
  height: 14.58px;
`;