import { useRef } from 'react';
import { pickBy } from 'lodash-es';
import { Task, Either } from 't-tasks';
import { useGeneratorEffect } from 't-hooks';
import { transitionDelay } from 'theme';

export const defined = <T>(x: T): x is Exclude<T, undefined> => typeof x !== 'undefined';

export const pickDefined = <T>(x: Record<string, T>) => pickBy(x, defined);

export const minimum = <T>(task: Task<T>, delay: number) => {
  return Task.all<Either<T, any>>([
    task
      .map((v) => Either.right(v))
      .mapRejected((e) => Either.left(e)),
    Task.timeout(delay).map(() => ({} as never)),
  ]).chain(([either]) => either.matchMap<Task<T>>({
    right: (v) => Task.resolved(v),
    left: (e) => Task.rejected(e),
  }).right);
};

export const useRealtimeRef = <T>(value: T) => {
  const valueRef = useRef(value);

  valueRef.current = value;

  return valueRef;
};

export const useAutorun = (callback: () => void, delay: number = transitionDelay) => {
  const callbackRef = useRealtimeRef(callback);
  const delayRef = useRealtimeRef(delay);

  useGeneratorEffect(function* () {
    yield* Task.timeout(delayRef.current).generator();

    callbackRef.current();
  }, []);
};

export const consOrEqual = (value: string) => {
  if ([...value].every((char, _, arr) => char === arr[0])) {
    return true;
  }

  const differenceAry = [...value].slice(1).map((n, i) => +n - +[...value][i]);
  const isDifference = differenceAry.every((char) => +char === 1);

  return isDifference;
};

export const hexToString = function (str1: string) {
  var hex = str1.toString();
  var str = '';

  for (var n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }

  return str;
}

export const sliceString = (value: string) => {
  if (value.length < 11) {
    return value;
  }

  return value.slice(0, 5) + '...' + value.slice(-4);
}
