export const en = {
  'FaceTec_accessibility_cancel_button': 'Cancel',

  'FaceTec_initializing_camera': 'Securing camera feed',

  'FaceTec_feedback_center_face': 'Center your face',
  'FaceTec_feedback_face_not_found': 'Frame your face',
  'FaceTec_feedback_move_phone_away': 'Move away',
  'FaceTec_feedback_move_away_web': 'Move away',
  'FaceTec_feedback_move_phone_closer': 'Move closer',
  'FaceTec_feedback_move_phone_to_eye_level': 'Move phone to eye level',
  'FaceTec_feedback_move_to_eye_level_web': 'Look straight into camera',
  'FaceTec_feedback_face_not_looking_straight_ahead': 'Look straight ahead',
  'FaceTec_feedback_face_not_upright': 'Hold Your Head Straight',
  'FaceTec_feedback_face_not_upright_mobile': 'Keep head straight',
  'FaceTec_feedback_hold_steady': 'Hold steady',
  'FaceTec_feedback_move_web_closer': 'Move face closer & fill oval',
  'FaceTec_feedback_move_web_even_closer': 'Even closer',
  'FaceTec_feedback_use_even_lighting': 'Light face more evenly',

  'FaceTec_instructions_header_ready_desktop': 'Get ready for identification',
  'FaceTec_instructions_header_ready_1_mobile': 'Get ready for',
  'FaceTec_instructions_header_ready_2_mobile': 'identification',
  'FaceTec_instructions_message_ready_desktop': 'Frame your face in the oval, press I\'m ready & move closer',
  'FaceTec_instructions_message_ready_1_mobile': 'Frame your face in the oval,',
  'FaceTec_instructions_message_ready_2_mobile': 'Press I\'m ready & move closer',

  'FaceTec_action_im_ready': 'I\'M READY',
  'FaceTec_presession_frame_your_face': 'Frame your face in the oval',
  'FaceTec_presession_look_straight_ahead': 'Look straight ahead',
  'FaceTec_presession_hold_steady3': 'Hold steady for: 3',
  'FaceTec_presession_hold_steady2': 'Hold steady for: 2',
  'FaceTec_presession_hold_steady1': 'Hold steady for: 1',
  'FaceTec_presession_eyes_straight_ahead': 'Look straight ahead',
  'FaceTec_presession_remove_dark_glasses': 'Remove dark glasses',
  'FaceTec_presession_neutral_expression': 'Neutral expression, no smiling',
  'FaceTec_presession_conditions_too_bright': 'Conditions too bright',
  'FaceTec_presession_brighten_your_environment': 'Brighten your environment',

  'FaceTec_result_facescan_upload_message': 'Uploading encrypted 3D FaceScan',

  'FaceTec_retry_header': 'Let\'s try that again',
  'FaceTec_retry_subheader_message': 'We need a clearer video selfie',
  'FaceTec_retry_your_image_label': 'Your selfie',
  'FaceTec_retry_ideal_image_label': 'Ideal pose',
  'FaceTec_retry_instruction_message_1': 'No glare or extreme lighting',
  'FaceTec_retry_instruction_message_2': 'Neutral expression, no smiling',
  'FaceTec_retry_instruction_message_3': 'Too blurry, clean camera',
  'FaceTec_action_try_again': 'TRY AGAIN',

  'FaceTec_camera_feed_issue_header': 'Issue securing camera feed',
  'FaceTec_camera_feed_issue_header_mobile': 'Issue securing<br/>camera feed',
  'FaceTec_camera_feed_issue_subheader_message_mobile': 'This App blocks suspicious webcam configurations.',
  'FaceTec_camera_feed_issue_subheader_message': 'This system cannot be verified due to the following:',
  'FaceTec_camera_feed_issue_table_header_1': 'Possible issue',
  'FaceTec_camera_feed_issue_table_header_2': 'Fix',
  'FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error': 'Camera permissions not remembered in Firefox.',
  'FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error': 'Check remember permissions.',
  'FaceTec_camera_feed_issue_table_row_1_cell_1': 'Camera already in use by another App.',
  'FaceTec_camera_feed_issue_table_row_1_cell_2': 'Close the other App.',
  'FaceTec_camera_feed_issue_table_row_2_cell_1': 'A 3rd-Party App is modifying the video.',
  'FaceTec_camera_feed_issue_table_row_2_cell_2': 'Close/Uninstall the other App.',
  'FaceTec_camera_feed_issue_table_row_3_cell_1': 'Hardware not capable of being secured.',
  'FaceTec_camera_feed_issue_table_row_3_cell_2': 'Use a different Device.',
  'FaceTec_camera_feed_issue_subtable_message': 'This App blocks suspicious webcam configurations.',
  'FaceTec_camera_feed_issue_action': 'TRY AGAIN ANYWAY',
  'FaceTec_camera_feed_issue_action_firefox_permissions_error': 'OK',

  'FaceTec_camera_permission_header': 'Enable camera',
  'FaceTec_camera_permission_message': 'Camera permissions disabled.<br/>Please check your operating system and browser settings.',
  'FaceTec_action_ok': 'OK',

  'FaceTec_result_success_message': 'Success',
};

export default en;
