import { ReactNode, Ref } from 'react';
import { ThemeColor, ThemeTRBL } from 'theme';
import { ClassNameProps, OptionalHandle, VerticalArrangement } from '@spatium/wallet-kit';

import { Modal } from 'lib/modal';

import { Backdrop, Content, ContentWrapper, ModalWrapper } from './modal.styles';

export type ExtendedProps<T extends {}> = T & {
  animating: boolean;
  close: () => void;
};

export type ModalProps<T extends {}> = {
  children(p: ExtendedProps<T>): ReactNode;
  modalRef?: Ref<OptionalHandle<T>>;
  initial?: T;
  stretch?: boolean;
  themeColor?: ThemeColor;
  themePadding?: ThemeTRBL;
  verticalAlign?: VerticalArrangement;
} & ClassNameProps;

export const ModalContainer = <T extends {}>({ children, stretch, modalRef, initial, style, className, themePadding, themeColor, verticalAlign }: ModalProps<T>) => (
  <Modal<T> modalRef={modalRef} initial={initial} transitionDuration={300}>
    {({ close, transitionState, transitionDuration, props }) => (
      <ModalWrapper>
        <Backdrop themeColor={themeColor} transitionState={transitionState} transitionDuration={transitionDuration} />
        <ContentWrapper themePadding={themePadding} verticalAlign={verticalAlign}>
          <Content
            stretch={stretch}
            className={className}
            style={style}
            verticalAlign={verticalAlign}
            transitionState={transitionState}
            transitionDuration={transitionDuration}
          >
            {children({ close, ...props, animating: ['entering', 'exiting'].includes(transitionState) })}
          </Content>
        </ContentWrapper>
      </ModalWrapper>
    )}
  </Modal>
);
