import { FC, memo } from 'react';
import { ClassNameProps } from '@spatium/wallet-kit';

export type MetaLogoProps = {
  size?: number;
};

export const MetaLogo: FC<MetaLogoProps & ClassNameProps> = memo(({
  size = 24,
  className,
  style,
}) => (
  <svg className={className} style={style} width={size} height={size / 1.09090909} viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_528_3974)">
      <path d="M23.4437 0L13.1455 7.45057L15.0605 3.05974L23.4437 0Z" fill="#E17726" />
      <path d="M0.580359 0.0078125L8.94108 3.0593L10.7595 7.5077L0.580359 0.0078125ZM19.2993 15.8443L23.851 15.929L22.2602 21.2129L16.7062 19.7177L19.2993 15.8443ZM4.70048 15.8443L7.28395 19.7177L1.7393 21.213L0.158203 15.929L4.70048 15.8443Z" fill="#E27625" />
      <path d="M10.5124 6.37414L10.6984 12.2484L5.13232 12.0008L6.71557 9.66534L6.73564 9.64288L10.5124 6.37414ZM13.4301 6.30859L17.2645 9.64306L17.2844 9.66543L18.8676 12.0009L13.3028 12.2484L13.4301 6.30859ZM7.44711 15.8609L10.4863 18.1763L6.95586 19.8429L7.44711 15.8609ZM16.5536 15.8605L17.0346 19.843L13.514 18.1761L16.5536 15.8605Z" fill="#E27625" />
      <path d="M13.5916 17.9609L17.1641 19.6524L13.841 21.1966L13.8755 20.176L13.5916 17.9609ZM10.4072 17.9617L10.1345 20.1593L10.1569 21.1954L6.82593 19.6524L10.4072 17.9617Z" fill="#D5BFB2" />
      <path d="M9.3757 13.0156L10.3093 14.934L7.13086 14.0236L9.3757 13.0156ZM14.6243 13.0158L16.8797 14.0236L13.6909 14.9338L14.6243 13.0158Z" fill="#233447" />
      <path d="M7.68983 15.8435L7.17608 19.9721L4.42236 15.9338L7.68983 15.8435ZM16.3101 15.8436L19.5777 15.9338L16.8137 19.9723L16.3101 15.8436ZM18.9479 11.7695L16.5699 14.1392L14.7365 13.32L13.8587 15.1243L13.2832 12.0215L18.9479 11.7695ZM5.05068 11.7695L10.7165 12.0215L10.1409 15.1243L9.26296 13.3203L7.43924 14.1393L5.05068 11.7695Z" fill="#CC6228" />
      <path d="M4.89062 11.2821L7.58106 13.9515L7.67425 16.5869L4.89062 11.2821ZM19.1122 11.2773L16.3235 16.5915L16.4285 13.9515L19.1122 11.2773ZM10.5851 11.4447L10.6934 12.1111L10.9609 13.7713L10.7889 18.8703L9.97563 14.7742L9.97534 14.7318L10.5851 11.4447ZM13.4136 11.4355L14.025 14.7318L14.0247 14.7742L13.2093 18.8805L13.1771 17.8534L13.0499 13.7411L13.4136 11.4355Z" fill="#E27525" />
      <path d="M16.6677 13.8477L16.5767 16.1373L13.7385 18.2994L13.1647 17.9031L13.8078 14.664L16.6677 13.8477ZM7.34229 13.8477L10.1922 14.664L10.8353 17.9031L10.2616 18.2994L7.42329 16.1371L7.34229 13.8477Z" fill="#F5841F" />
      <path d="M6.28296 19.1445L9.91408 20.8268L9.89871 20.1084L10.2026 19.8476H13.7964L14.1112 20.1075L14.0879 20.8253L17.6961 19.1487L15.9403 20.5674L13.8174 21.9931H10.1734L8.05183 20.5615L6.28296 19.1445Z" fill="#C0AC9D" />
      <path d="M13.3317 17.7344L13.8451 18.089L14.1459 20.4361L13.7105 20.0766H10.2909L9.86377 20.4433L10.1548 18.0892L10.6683 17.7344H13.3317Z" fill="#161616" />
      <path d="M22.7638 0.207031L24 3.83309L23.228 7.49948L23.7777 7.91409L23.0338 8.46904L23.5929 8.89126L22.8526 9.55043L23.3071 9.87227L22.1009 11.2497L17.1537 9.8412L17.1109 9.81874L13.5458 6.87826L22.7638 0.207031ZM1.23619 0.207031L10.4543 6.87826L6.88912 9.81874L6.84628 9.8412L1.89909 11.2497L0.692906 9.87227L1.14703 9.55071L0.407156 8.89126L0.965156 8.4695L0.210094 7.91299L0.780563 7.49811L0 3.83327L1.23619 0.207031Z" fill="#763E1A" />
      <path d="M16.9117 9.53231L22.1535 11.0245L23.8565 16.1566H19.3636L16.268 16.1947L18.5193 11.904L16.9117 9.53231ZM7.08818 9.53231L5.48027 11.904L7.73186 16.1947L4.63764 16.1566H0.152832L1.84624 11.0246L7.08818 9.53231ZM15.3172 3.03516L13.851 6.90706L13.5399 12.1376L13.4208 13.777L13.4113 17.9651H10.5884L10.5792 13.7849L10.4598 12.1362L10.1486 6.90706L8.68258 3.03516H15.3172Z" fill="#F5841F" />
    </g>
    <defs>
      <clipPath id="clip0_528_3974">
        <rect width="24" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
));
