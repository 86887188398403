/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../common";
import type {
  UnikaIdentificationRelay,
  UnikaIdentificationRelayInterface,
} from "../UnikaIdentificationRelay";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "clientAddress",
        type: "address",
      },
    ],
    name: "IdentificationRelayRequestReceived",
    type: "event",
  },
  {
    inputs: [],
    name: "initializeIdentification",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const _bytecode =
  "0x6080604052348015600f57600080fd5b5060a08061001e6000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c806384b7829b14602d575b600080fd5b60336035565b005b6040513381527fd9725fef92f1c077ae6c18ab8ef476a6f5bc11ce61b4674c7b331b0b5eb3553a9060200160405180910390a156fea264697066735822122068cd4ef6c6efcf226a6ef46948995d18157912962f7e83f2a2bb22692b56833d64736f6c63430008110033";

type UnikaIdentificationRelayConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: UnikaIdentificationRelayConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class UnikaIdentificationRelay__factory extends ContractFactory {
  constructor(...args: UnikaIdentificationRelayConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<UnikaIdentificationRelay> {
    return super.deploy(overrides || {}) as Promise<UnikaIdentificationRelay>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): UnikaIdentificationRelay {
    return super.attach(address) as UnikaIdentificationRelay;
  }
  override connect(signer: Signer): UnikaIdentificationRelay__factory {
    return super.connect(signer) as UnikaIdentificationRelay__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): UnikaIdentificationRelayInterface {
    return new utils.Interface(_abi) as UnikaIdentificationRelayInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): UnikaIdentificationRelay {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as UnikaIdentificationRelay;
  }
}
